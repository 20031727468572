import axios, { AxiosResponse } from 'axios';
import { AdminCommentIndexRequest, AdminCommentUpdateRequest } from 'src/models/apis/comment/adminCommentRequest';
import { AdminCommentIndexResponse } from 'src/models/apis/comment/adminCommentResponse';
import { CommentIndexParams } from 'src/models/apis/comment/commentRequest';
import { CommentIndexResponse, Comment } from 'src/models/apis/comment/commentResponse';
import * as utils from './apiUtils';

export default {
  index(params: CommentIndexParams): Promise<AxiosResponse<CommentIndexResponse>> {
    return axios.get(utils.getApiUrl('/comments'), {
      params: params || {},
    });
  },
  get(id: number): Promise<AxiosResponse<Comment>> {
    return axios.get(utils.getApiUrl(`/comments/${id}`));
  },
  create(params: Comment): Promise<AxiosResponse<Comment>> {
    const formData = utils.transformFormData(params);
    return axios.post(utils.getApiUrl('/comments'), formData);
  },
  update(id: number, params: Comment): Promise<AxiosResponse<Comment>> {
    const formData = utils.transformFormData(params);
    // putではFormDataのパラメータを受け取れないため
    // https://qiita.com/ky0he1_0t4/items/6cd8264f646bdf86cdb9
    return axios.post(utils.getApiUrl(`/comments/${id}`), formData, {
      headers: {
        'X-HTTP-Method-Override': 'PUT',
      },
    });
  },
  destroy(id: number): Promise<AxiosResponse<Comment>> {
    return axios.delete(utils.getApiUrl(`/comments/${id}`));
  },

  adminIndex(params: AdminCommentIndexRequest): Promise<AxiosResponse<AdminCommentIndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/comments'), {
      params: params || {},
    });
  },
  adminUpdate(id: number, params: AdminCommentUpdateRequest): Promise<AxiosResponse<Comment>> {
    const formData = utils.transformFormData(params);
    return axios.post(utils.getApiUrl(`/admin/comments/${id}`), formData, {
      headers: {
        'X-HTTP-Method-Override': 'PUT',
      },
    });
  },
  adminDestroy(id: number): Promise<AxiosResponse<Comment>> {
    return axios.delete(utils.getApiUrl(`/admin/comments/${id}`));
  },
};
