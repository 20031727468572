


import {
  defineComponent,
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import boardEntryApi from '@/apis/board_entry';
import {
  BoardTab,
  BoardEntry,
  BoardEntryFile,
} from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import { dtFormat } from '@/lib/dateHelper';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import SearchPane from './components/SearchPane/index.vue';
import AttachedFilesViewArea from './components/AttachedFilesViewArea/index.vue';
import BoardEntryEditModal from './components/BoardEntryEditModal/index.vue';
import PhotoModal from '@/components/lib/PhotoModal/index.vue';
import {
  BOARD_ENTRY_ATTACHED_TO_CONTENT,
  BOARD_ENTRY_ATTACHED_TO_REPLY,
  SearchParams,
  convSearchParamDateFromTo,
  fileTypeIconFromFilePath,
} from './utils/index';
import { BoardEntryIndexParams } from '@/models/apis/settouMessageBoard/settouMessageBoardRequest';
import {
  SettouMessageBoardState,
  BoardEntryConv,
} from '@/models/settouMessageBoard';
import { ModalPhoto, PhotoModalState } from '@/models/photoModal';
import {
  johaisetsuRoleMap,
} from '@/components/SettouMessageBoard/consts/settou_message_board';
import { fetchFileAsObjectInfo } from '@/lib/fileHelper';
import useBoardDisplayControl, { provideBoardDisplayControl }
  from '@/components/SettouMessageBoard/composables/useBoardDisplayControl';

export default defineComponent({
  name: 'settouMessageBoard',
  setup() {
    const state = reactive<SettouMessageBoardState>({
      boardTabs: [],
      currentTabId: 0,
      filteredTabMinIdx: 0,
      maxFilteredTabCount: 3,
      hanMsts: [],
      hanChoices: [],
      hanChoicesMap: {},
      boardEntries: [],
      editingBoardEntry: null,
      showEditModal: false,
      showDeleteModal: false,
      deleteBody: '',
      shouldShowErrorModal: false,
      errorBody: '',
      destroyId: null,
      pager: {
        currentPage: 1,
        itemsPerPage: 50,
      },

      onResizeFunc: (): void => {},
    });
    const photoModalState = reactive<PhotoModalState>({
      shouldShowPhotoModal: false,
      title: '添付写真',
      photos: [],
    });
    const store = useStore();
    const userState = store.state.user;
    const tabBar = ref<HTMLElement>();
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });

    const {
      currentBoardTab,
      currentBoardRole,
      isCreatableRole,
      isRepliableRole,
      isHonbuBoard,
      isMaintenanceBoard,
      canUseHonbuReportSearchConditions,
      boardTabDepartmentDisp,
    } = (() => {
      const injectedState = provideBoardDisplayControl(state);
      return useBoardDisplayControl(injectedState);
    })();

    const shouldShowEditModal = computed<boolean>(() => {
      if (state.shouldShowErrorModal) return false;
      return state.showEditModal;
    });
    const shouldShowDeleteModal = computed<boolean>(() => {
      if (state.shouldShowErrorModal) return false;
      return state.showDeleteModal;
    });
    const filteredBoardTabs = computed<BoardTab[]>(() => {
      const maxIdx = state.filteredTabMinIdx + state.maxFilteredTabCount;
      return state.boardTabs.filter((e, i) => {
        return i >= state.filteredTabMinIdx && i < maxIdx;
      });
    });
    const beforeFilteredBoardTabs = computed<BoardTab[]>(() => {
      return state.boardTabs.filter((e, i) => {
        return i < state.filteredTabMinIdx;
      });
    });
    const afterFilteredBoardTabs = computed<BoardTab[]>(() => {
      const maxIdx = state.filteredTabMinIdx + state.maxFilteredTabCount;
      return state.boardTabs.filter((e, i) => {
        return i >= maxIdx;
      });
    });
    const paginatedBoardEntries = computed<BoardEntryConv[]>(() => {
      const nextPageIdx = state.pager.currentPage * state.pager.itemsPerPage;
      const minIdx = nextPageIdx - state.pager.itemsPerPage;
      return state.boardEntries.filter((e, i) => {
        return i >= minIdx && i < nextPageIdx;
      });
    });

    onMounted(async() => {
      initResizeFunc();
      await waitForUserAndMasters();
      await getBoardHanMasters();
      await prepareBoardTabs();
    });
    const initResizeFunc = (): void => {
      state.onResizeFunc = () => {
        // resizeされたときにタブの最大表示数を変更する
        const tabBarW = tabBar.value?.clientWidth ?? window.innerWidth;
        const btnsW = document.getElementById('tab-pager')?.clientWidth ?? 0;
        state.maxFilteredTabCount = parseInt(((tabBarW - btnsW) / 160).toString());
      };
      state.onResizeFunc();
      window.addEventListener('resize', state.onResizeFunc);
    };
    const prepareBoardTabs = async(): Promise<void> => {
      const res = await boardEntryApi.getBoardTabs();
      state.boardTabs = res.data;
      // 所属組織の掲示板をデフォルトで表示
      const ownCompanyBoardTabIdx = state.boardTabs.findIndex(e => e.owner_company_name === userState.johaisetsu_role);
      if (ownCompanyBoardTabIdx < 0) {
        state.currentTabId = state.boardTabs[0].id;
        return;
      }
      state.currentTabId = state.boardTabs[ownCompanyBoardTabIdx].id;
      if (ownCompanyBoardTabIdx < state.maxFilteredTabCount) {
        return;
      }
      // 所属組織のタブが表示範囲外の場合、一番右側に表示されるように範囲を移動する
      state.filteredTabMinIdx = ownCompanyBoardTabIdx - state.maxFilteredTabCount + 1;
    };
    const getBoardHanMasters = async(): Promise<void> => {
      const res = await boardEntryApi.getBoardHanMasters();
      state.hanMsts = res.data;
    };
    const refreshHanChoices = (): void => {
      const boardTabJohaisetsuRole = johaisetsuRoleMap[currentBoardTab.value.owner_company_name];
      state.hanChoices = state.hanMsts.filter(e => e.johaisetsu_role === boardTabJohaisetsuRole);
      state.hanChoicesMap = {};
      for (const han of state.hanMsts) {
        state.hanChoicesMap[han.id] = han.name.toString();
      }
    };
    let latestSearchParams: SearchParams | null = null;
    const searchBoardEntries = async(params: SearchParams): Promise<void> => {
      latestSearchParams = params;
      state.pager.currentPage = 1;
      state.boardEntries = [];
      refreshHanChoices();
      const [dateFrom, dateTo] = convSearchParamDateFromTo(params.dateFrom, params.dateTo);
      const searchParams: BoardEntryIndexParams = {
        board_master_id: state.currentTabId,
        date_from: dateFrom,
        date_to: dateTo,
      };
      const res = await boardEntryApi.index(searchParams);
      // 素早く板を切り替えた時に別の板の投稿が表示されてしまうのを防ぐ
      if (res.data[0]?.board_master_id !== state.currentTabId) {
        return;
      }
      const entries = filterBoardEntriesWithSearchParams(res.data, params);
      state.boardEntries = await convSettouBoardEntriesResponse(entries);
    };
    const reloadBoardEntries = async(): Promise<void> => {
      if (!latestSearchParams) return;
      searchBoardEntries(latestSearchParams);
    };
    const filterBoardEntriesWithSearchParams = (entries: BoardEntry[], params: SearchParams): BoardEntry[] => {
      if (params.han) {
        entries = entries.filter(e => e.han === params.han);
      }
      if (params.honshaReportNeeded === 1) {
        entries = entries.filter(e => e.is_honsha_report_needed);
      }
      if (params.reportToHonsha === 1) {
        entries = entries.filter(e => e.is_reported_to_honsha);
      }
      const companyName = params.companyName;
      if (companyName) {
        entries = entries.filter(e => e.company_name && e.company_name.indexOf(companyName) >= 0);
      }
      return entries;
    };
    const onClickTabListItem = async(board_master_id: number): Promise<void> => {
      const boardTabIdx = state.boardTabs.findIndex(e => e.id === board_master_id);
      if (boardTabIdx < 0) return;
      state.currentTabId = board_master_id;
      if (boardTabIdx >= state.boardTabs.length - state.maxFilteredTabCount) {
        state.filteredTabMinIdx = state.boardTabs.length - state.maxFilteredTabCount;
      } else {
        state.filteredTabMinIdx = boardTabIdx;
      }
    };
    const convSettouBoardEntriesResponse = async(entries: BoardEntry[]): Promise<BoardEntryConv[]> => {
      const converted = entries.map((e, i) => {
        return {
          ...e,
          dispNo: entries.length - i,
          hanDisp: (state.hanChoicesMap[e.han ?? 0] || ''),
          formattedContent: convertLinkInText(e.content),
          formattedReply: convertLinkInText(e.reply),
          formattedBikou: convertLinkInText(e.bikou),
        };
      });
      // 本社ユーザーは本社本部報告が必要かつ掲示の場合、投稿を表示する
      if (userState.johaisetsu_role === 'honsha') {
        return converted.filter(e => e.is_honsha_report_needed && e.is_reported_to_honsha);
      }
      return converted;
    };
    const convertLinkInText = (text: string | null): string => {
      if (!text) return '';

      const regExpUrl = /\b(https?:\/\/[\S]+)\b/g;
      const regExpDrivePath = /\b([A-Za-z]:\\[\S]+)\b/g;
      return text.replaceAll(regExpUrl, (url) => {
        return `<a href="${url}" target="_blank">${url}<i class="fa fa-external-link-alt ml3"></i></a>`;
      }).replaceAll(regExpDrivePath, (path) => {
        return `<a href="" onclick="
          if (!navigator.clipboard) { return false; }
          navigator.clipboard.writeText('${path.replaceAll('\\', '\\\\')}');
          return false;
        ">${path}<i class="far fa-copy ml3"></i></a>`;
      });
    };
    const createBoardEntry = (): void => {
      state.showEditModal = true;
    };
    const editBoardEntry = (origFormObj: BoardEntryConv): void => {
      state.editingBoardEntry = origFormObj;
      state.showEditModal = true;
    };
    const saveBoardEntry = async() => {
      state.editingBoardEntry = null;
      state.showEditModal = false;
      reloadBoardEntries();
    };
    const cancelEditBoardEntry = (): void => {
      state.editingBoardEntry = null;
      state.showEditModal = false;
    };
    const tryDeleteBoardEntry = (formObj: BoardEntryConv): void => {
      if (formObj.id) {
        state.destroyId = formObj.id;
      }
      state.deleteBody = `投稿No.${formObj.dispNo}を削除してもよろしいですか？`;
      state.showDeleteModal = true;
    };
    const deleteBoardEntry = async(): Promise<void> => {
      try {
        await boardEntryApi.destroy(state.destroyId || 0);
        reloadBoardEntries();
        state.showDeleteModal = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = '削除に失敗しました。再度操作を行ってください';
        state.shouldShowErrorModal = true;
      }
    };
    const prevClick = (): void => {
      if (state.filteredTabMinIdx === 0) return;
      state.filteredTabMinIdx = state.filteredTabMinIdx - 1;
    };
    const nextClick = (): void => {
      if (state.filteredTabMinIdx >= state.boardTabs.length - state.maxFilteredTabCount) return;
      state.filteredTabMinIdx = state.filteredTabMinIdx + 1;
    };
    const isEditable = (item: BoardEntryConv): boolean => {
      if (isSuperAdmin.value || isRepliableRole.value) return true;
      return userState.id === item.created_by;
    };
    const isDeletable = (item: BoardEntryConv): boolean => {
      if (isSuperAdmin.value) return true;
      return userState.id === item.created_by;
    };
    const showPhotoModal = async(images: BoardEntryFile[]) => {
      photoModalState.photos = [];
      for (let i = 0; i < images.length; i++) {
        const imageObjInfo = await fetchFileAsObjectInfo(images[i].file_path);
        if (!imageObjInfo || !imageObjInfo.url) { return; }
        const photo: ModalPhoto = {
          id: images[i].id,
          photoTitle: String(i + 1),
          savedImage: imageObjInfo.url,
        };
        photoModalState.photos.push(photo);
      }
      photoModalState.shouldShowPhotoModal = true;
    };
    const hidePhotoModal = () => {
      photoModalState.shouldShowPhotoModal = false;
    };
    onUnmounted(() => {
      window.removeEventListener('resize', state.onResizeFunc);
    });
    return {
      // consts
      BOARD_ENTRY_ATTACHED_TO_CONTENT,
      BOARD_ENTRY_ATTACHED_TO_REPLY,
      // state
      state,
      photoModalState,
      tabBar,
      johaisetsuRoleMap,
      // computed
      isSuperAdmin,
      shouldShowEditModal,
      shouldShowDeleteModal,
      currentBoardTab,
      currentBoardRole,
      isHonbuBoard,
      isMaintenanceBoard,
      canUseHonbuReportSearchConditions,
      boardTabDepartmentDisp,
      isCreatableRole,
      isRepliableRole,
      filteredBoardTabs,
      beforeFilteredBoardTabs,
      afterFilteredBoardTabs,
      paginatedBoardEntries,
      // methods
      onClickTabListItem,
      createBoardEntry,
      editBoardEntry,
      saveBoardEntry,
      cancelEditBoardEntry,
      tryDeleteBoardEntry,
      deleteBoardEntry,
      prevClick,
      nextClick,
      isEditable,
      isDeletable,
      showPhotoModal,
      hidePhotoModal,
      fileTypeIconFromFilePath,
      dtFormat,
      searchBoardEntries,
    };
  },
  components: {
    SearchPane,
    AttachedFilesViewArea,
    PhotoModal,
    BoardEntryEditModal,
  },
});
