import { BoardEntryEdit } from '@/models/settouMessageBoard';

export const LONG_TEXT_MAX_LENGTH = 3000;
export const ATTACHMENT_FILE_MAX_MB_DEFAULT = 10;
export const MAX_SAVABLE_FILES_COUNT = 3;
export const editingBoardEntryDefault: BoardEntryEdit = {
  id: 0,
  dispNo: null,
  timestamp: '',
  date: null,
  time: null,
  companyName: null,
  han: null,
  isHonshaReportNeeded: false,
  isReportedToHonsha: false,
  content: null,
  reply: null,
  bikou: null,
  saveFiles: [],
  removeFileIds: [],
};
