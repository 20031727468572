


import {
  defineComponent,
  computed,
  onMounted,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import ReportModePane from '@/components/CleaningMap/components/ReportsView/ReportModePane/index.vue';
import MapMiscInfo from '@/components/CleaningMap/components/common/MapMiscInfo.vue';
import PhotoModal from '@/components/lib/PhotoModal/index.vue';
import { CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';
import { CleaningReportExt, CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { ABILITY_CLEANING_MAP } from '@/consts/ability';
import ManualDownloadLinks from '@/components/CleaningMap/components/common/ManualDownloadLinks/index.vue';
import usePaneControl from '@/components/CleaningMap/composables/usePaneControl';
import { useCleaningReports } from '@/components/CleaningMap/composables/useCleaningReports';
import { useExtremeMap } from '@/components/CleaningMap/composables/useExtremeMap';
import { usePhotoModal } from '@/components/CleaningMap/composables/usePhotoModal';
import { REPORT_LIST_MAX_HEIGHT_RATE, REPORT_LIST_MIN_HEIGHT_RATE } from '@/components/CleaningMap/consts/cleaning_map';
import { fetchImageAsObjectURL } from '@/lib/imageHelper';
import { useWaitSpinner } from '@/components/CleaningMap/composables/useWaitSpinner';

export default defineComponent({
  setup() {
    const store = useStore();
    const userState = store.state.user;
    const shouldShowManualDownloadLinks = computed<boolean>(() => {
      return !!userState.abilityMap[ABILITY_CLEANING_MAP];
    });

    const {
      paneState,
      onChangeShowCleaningPhotoIcons: onChangeShowCleaningPhotoIconsOrig,
      onChangeShowDefectPhotoIcons: onChangeShowDefectPhotoIconsOrig,
    } = usePaneControl({
      paneStyleLimitMap: {
        listMinHeightRate: REPORT_LIST_MIN_HEIGHT_RATE,
        listMaxHeightRate: REPORT_LIST_MAX_HEIGHT_RATE,
      },
    });

    const {
      extremeMapRef,
      mapViewState,
      paneStyle,
      loadExtremeMapEssentials,
      forceResizeExtremeMap,
      showCleaningReportLayer,
      removeCleaningReportLayer,
      showCleaningReportPhotoPopup,
      showCleaningMtxPopup,
      hidePopup,
    } = useExtremeMap(paneState);

    const {
      searchReports,
      selectReport,
      deselectReport,
      getSelectedReport,
      deselectSelectedReportPhotos,
    } = useCleaningReports();

    const {
      photoModalState,
      showPhotoModal: showPhotoModalOrig,
      hidePhotoModal,
    } = usePhotoModal();
    const showPhotoModal = async(photoType: string, photos: CleaningReportPhotoExt[]) => {
      waitSpinnerState.showWaitSpinner = photos.some(photo => !photo.savedImage);
      const promises = photos.map<Promise<void>>(async(photo) => {
        if (photo.savedImage) return;
        photo.savedImage = await fetchImageAsObjectURL(photo.image_path);
      });
      await Promise.all(promises);
      waitSpinnerState.showWaitSpinner = false;
      showPhotoModalOrig(photoType, photos);
    };

    const waitSpinnerState = useWaitSpinner();

    const redrawCleaningReportLayer = (fitToExtent = false) => {
      const selectedReport = getSelectedReport();
      if (!selectedReport) return;

      showCleaningReportLayer(selectedReport, fitToExtent);
    };

    const onChangeShowCleaningPhotoIcons = () => {
      onChangeShowCleaningPhotoIconsOrig();
      redrawCleaningReportLayer();
    };
    const onChangeShowDefectPhotoIcons = () => {
      onChangeShowDefectPhotoIconsOrig();
      redrawCleaningReportLayer();
    };

    const onClickReport = async(selectedReport: CleaningReportExt) => {
      hidePopup();
      // 選択中の報告書をクリックした場合、選択を解除する
      if (deselectReport(selectedReport.id)) {
        removeCleaningReportLayer();
        return;
      }
      await selectReport(selectedReport.id);
      redrawCleaningReportLayer(true);
    };

    const onClickPhotoPin = async(photo: CleaningReportPhotoExt) => {
      const isSelected = photo.isSelected;
      hidePopup();
      deselectSelectedReportPhotos();
      photo.isSelected = !isSelected;
      if (photo.isSelected) {
        // 画像を取得する
        if (!photo.savedImage) {
          waitSpinnerState.showWaitSpinner = true;
          photo.savedImage = await fetchImageAsObjectURL(photo.image_path);
          waitSpinnerState.showWaitSpinner = false;
        }
        showCleaningReportPhotoPopup(photo);
      }
      redrawCleaningReportLayer();
    };
    const onClickMtx = (mtx: CleaningMtxExt) => {
      const isSelected = mtx.isSelected;
      deselectSelectedReportPhotos();
      hidePopup();
      mtx.isSelected = !isSelected;
      if (mtx.isSelected) {
        showCleaningMtxPopup(mtx);
      }
      redrawCleaningReportLayer();
    };
    const onAllDeselected = () => {
      hidePopup();
      deselectSelectedReportPhotos();
      redrawCleaningReportLayer();
    };

    const paneResizeStopped = () => {
      forceResizeExtremeMap();
    };

    onMounted(async() => {
      waitSpinnerState.showWaitSpinner = true;
      await Promise.all([loadExtremeMapEssentials(), searchReports()]);
      waitSpinnerState.showWaitSpinner = false;
    });

    return {
      mapViewState,
      paneState,
      paneStyle,
      photoModalState,
      // ref
      extremeMapRef,
      // computed
      shouldShowManualDownloadLinks,
      // methods
      onClickReport,
      onClickPhotoPin,
      onClickMtx,
      onAllDeselected,
      onChangeShowCleaningPhotoIcons,
      onChangeShowDefectPhotoIcons,
      paneResizeStopped,
      showPhotoModal,
      hidePhotoModal,
    };
  },
  components: {
    ReportModePane,
    PhotoModal,
    ManualDownloadLinks,
    MapMiscInfo,
  },
});
