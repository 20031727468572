import {
  CarExt,
  SettouPatrolReportExt,
  EnsuiPlantExt,
  CleaningCarExt,
  WeatherObservatoryExt,
} from '@/models/index';
import { CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';
import { JohaisetsuMtxExt } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';
import { cleaningPhotoTypeOptions } from '@/components/CleaningMap/consts/cleaning_photo';
import { dtFormat } from './dateHelper';
import { workTypes } from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { WeatherObservatoryDataPoint } from '@/models/apis/weatherObservatory/weatherObservatoryResponse';
import { JohaisetsuCarExt } from '@/models/johaisetsu/johaisetsuCar';
import { isValidWorkType } from '@/lib/johaisetsu/johaisetsuHelper';
import { JohaisetsuSettouReport } from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import {
  SettouOnsiteManagementReportExt,
} from '@/models/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementReport';

export function getNormalCarPopupContent_(car: CarExt): string {
  let html = `<p class="title">【${car.device?.car_name}】</p>`;
  html += `
    <p class="info-row">
      <span class="lbl">状態:</span>
      <span class="val">${car.status_disp}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">車種：</span>
      <span class="val">${car.device?.carKindDisp}</span>
    </p>`;
  if (car.device?.is_publishing) {
    html += `
      <p class="btn-row">
        <button id="play-movie-button" class="btn btn-sm btn-success">
          <i class="fas fa-video"></i><span class="ib ml2">動画を再生</span>
        </button>
        <button id="stop-live-stream-button" class="btn btn-sm btn-info ml8">
          配信停止
        </button>
      </p>`;
  } else if (car.device?.is_publish_preparing) {
    html += `
      <p class="btn-row">
        <i class="fas fa-spinner fa-spin text-success"></i>
        <span class="text-success">配信準備中</span>
      </p>`;
  } else if (car.isMoving) {
    html += `
      <p class="btn-row">
        <button id="start-live-stream-button" class="btn btn-sm btn-info">
          配信開始
        </button>
      </p>`;
  }
  return html;
}
export function getExtCyzenPopupContent_(car: CarExt): string {
  let html = `<p class="title">【${car.username}】</p>`;
  html += `
    <p class="info-row">
      <span class="lbl">グループ:</span>
      <span class="val">${car.group_name}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">状態:</span>
      <span class="val">${car.status_disp}</span>
    </p>`;
  return html;
}
export function getExtSafiePopupContent_(car: CarExt): string {
  let html = `<p class="title">【${car.device?.car_name}】</p>`;
  if (car.device?.is_publishing) {
    html += `
      <p class="btn-row">
        <button id="play-movie-button" class="btn btn-sm btn-success">
          <i class="fas fa-video"></i><span class="ib ml2">動画を再生</span>
        </button>
        <button id="stop-live-stream-button" class="btn btn-sm btn-info ml8" disabled>
          配信停止
        </button>
      </p>`;
  } else {
    html += `
      <p class="btn-row">
        <button id="start-live-stream-button" class="btn btn-sm btn-info" disabled>
          配信開始
        </button>
      </p>`;
  }
  return html;
}
export function getExtJohaisetsuPopupContent_(car: CarExt): string {
  let html = `<p class="title">【${car.device?.car_name}】</p>`;
  if (!car.detail?.is_working) {
    html += `
      <p class="info-row">
        <span class="lbl">状態:</span>
        <span class="val">${car.status_disp}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="lbl">車種：</span>
        <span class="val">${car.device?.carKindDisp}</span>
      </p>`;
  } else {
    html += `
      <p class="info-row lg">
        <span class="lbl">状態:</span>
        <span class="val">${car.detail?.status_disp}</span>
      </p>`;
    html += `
      <p class="info-row lg">
        <span class="lbl">車種：</span>
        <span class="val">${car.device?.carKindDisp}</span>
      </p>`;
    if (car.detail?.sanpu_num) {
      html += `
        <p class="info-row lg">
          <span class="lbl">散布回数:</span>
          <span class="val">${car.detail?.sanpu_num}回目</span>
        </p>`;
    }
    html += `
      <p class="info-row lg">
        <span class="lbl">備考:</span>
        <span class="val">${car.detail?.bikou1}</span>
      </p>`;
    if (car.detail?.is_editable) {
      html += `
        <p class="btn-row">
          <button id="edit-johaisetsu-car-button" class="btn btn-sm btn-info">
            編集
          </button>
        </p>`;
    }
  }
  return html;
}
export function getExternalCarPopupContent_(car: CarExt): string {
  let ret = '';
  if (car.external_type === 'cyzen') {
    ret = getExtCyzenPopupContent_(car);
  } else if (car.external_type === 'safie') {
    ret = getExtSafiePopupContent_(car);
  } else if (car.external_type === 'johaisetsu') {
    ret = getExtJohaisetsuPopupContent_(car);
  }
  return ret;
}
export function getNormalSettouPatrolReportPopupContent_(report: SettouPatrolReportExt): string {
  let html = `<p class="title">【${report.johaisetsu_han_name}】</p>`;
  if (report.isJohaisetsuDisplay) {
    html += `
    <p class="info-row">
      <span class="val">作業種別:</span>
      <span class="val">雪凍パトロール報告</span>
    </p>`;
  }
  html += `
    <p class="info-row">
      <span class="val">日時:</span>
      <span class="val">${report.ts}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">天候:</span>
      <span class="val">${report.weather}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">気温：</span>
      <span class="val">${report.temperature ?? ''}&#8451;</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">路温：</span>
      <span class="val">${report.road_temperature ?? ''}&#8451;</span>
    </p>`;
  if (report.showDetailPageButton) {
    html += `
      <p class="btn-row">
        <button id="show-settou-patrol-report-detail-page-button" class="btn btn-sm btn-info">
          <i class="fas fa-external-link-alt"></i>
          詳細
        </button>
      </p>`;
  }
  return html;
}
export function getNormalSettouOnsiteManagementReportPopupContent_(report: SettouOnsiteManagementReportExt): string {
  const workProgress = report.work_progress
    ? report.work_progress.length > 56 ? report.work_progress.slice(0, 56) + '...' : report.work_progress
    : '';
  let html = `<p class="title">【${report.johaisetsu_han_name}】</p>`;
  html += `
  <p class="info-row">
    <span class="val">作業種別:</span>
    <span class="val">${report.work_type}</span>
  </p>`;
  html += `
    <p class="info-row">
      <span class="val">日時:</span>
      <span class="val">${report.ts}</span>
    </p>`;
  html += `
    <p class="info-row-wrap">
      <span class="val">作業状況:</span>
      <span class="val">${workProgress}</span>
    </p>`;
  if (report.showDetailPageButton) {
    html += `
      <p class="btn-row">
        <button id="show-settou-onsite-management-report-detail-page-button" class="btn btn-sm btn-info">
          <i class="fas fa-external-link-alt"></i>
          詳細
        </button>
      </p>`;
  }
  return html;
}
export function getNormalEnsuiPlantPopupContent_(plant: EnsuiPlantExt): string {
  let html = `<p class="title">【${plant.name} 塩水プラント】</p>`;
  html += `
    <p class="info-row">
      <span class="val">${plant.stock_info.as_of} 現在</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">塩水:</span>
      <span class="val">${plant.stock_info.ensui_quantity}m&sup3;</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">塩ナト:</span>
      <span class="val">${plant.stock_info.enka_natoriumu_quantity}t</span>
    </p>`;
  return html;
}
export function getCarPopupContentInnerHtml(car: CarExt): string {
  return !car.is_external_car
    ? getNormalCarPopupContent_(car)
    : getExternalCarPopupContent_(car);
}
export function getSettouPatrolReportPopupContentInnerHtml(report: SettouPatrolReportExt): string {
  return getNormalSettouPatrolReportPopupContent_(report);
}
export function getSettouOnsiteManagementReportPopupContentInnerHtml(report: SettouOnsiteManagementReportExt): string {
  return getNormalSettouOnsiteManagementReportPopupContent_(report);
}
export function getEnsuiPlantPopupContentInnerHtml(plant: EnsuiPlantExt): string {
  return getNormalEnsuiPlantPopupContent_(plant);
}
export function getWeatherObservatoryPopupContentInnerHtml(
  obervatory: WeatherObservatoryExt,
  dataPoints: WeatherObservatoryDataPoint[],
): string {
  let html = `<p class="title">【${obervatory.name}】</p>`;
  const isShowAirTemperature = dataPoints.filter(e => e.air_temperature !== null).length > 0;
  const isShowRoadTemperature = dataPoints.filter(e => e.road_temperature !== null).length > 0;
  const isShowHumidity = dataPoints.filter(e => e.humidity !== null).length > 0;
  const isShowRainfall = dataPoints.filter(e => e.ten_min_accumulated_rainfall !== null).length > 0;
  const isShowSnowDepth = dataPoints.filter(e => e.snow_depth !== null).length > 0;
  html += `
    <div class="tbl">
      <div class="tbl-header">
        <div class="bt br bl bb">時間</div>
        ${isShowAirTemperature ? '<div class="br bl bb">気温<br/>(&#8451;)</div>' : ''}
        ${isShowRoadTemperature ? '<div class="br bl bb">路温<br/>(&#8451;)</div>' : ''}
        ${isShowHumidity ? '<div class="br bl bb">湿度<br/>(%)</div>' : ''}
        ${isShowRainfall ? '<div class="br bl bb">積雨量<br/>(mm)</div>' : ''}
        ${isShowSnowDepth ? '<div class="br bl bb">積雪量<br/>(cm)</div>' : ''}
      </div>`;
  dataPoints.forEach((dataPoint) => {
    const airTemperatureDisp =
      dataPoint.air_temperature !== null
        ? `${Number(dataPoint.air_temperature).toFixed(1)}`
        : '';
    const roadTemperatureDisp =
      dataPoint.road_temperature !== null
        ? `${Number(dataPoint.road_temperature).toFixed(1)}`
        : '';
    const humidityDisp = dataPoint.humidity !== null ? `${dataPoint.humidity}` : '';
    const rainfallDisp =
      dataPoint.ten_min_accumulated_rainfall !== null
        ? `${dataPoint.ten_min_accumulated_rainfall}`
        : '';
    const snowDepthDisp = dataPoint.snow_depth !== null ? `${dataPoint.snow_depth}` : '';
    html += `
      <div class="tbl-header">
        <div class="bt br bb">${dtFormat(dataPoint.timestamp, 'HH:MM')}</div>
        ${isShowAirTemperature ? `<div class="data-col temperature-col">${airTemperatureDisp}</div>` : ''}
        ${isShowRoadTemperature ? `<div class="data-col temperature-col">${roadTemperatureDisp}</div>` : ''}
        ${isShowHumidity ? `<div class="data-col percentage-col">${humidityDisp}</div>` : ''}
        ${isShowRainfall ? `<div class="data-col rain-snow-amount-col">${rainfallDisp}</div>` : ''}
        ${isShowSnowDepth ? `<div class="data-col rain-snow-amount-col">${snowDepthDisp}</div>` : ''}
      </div>`;
  });
  html += `
    </div>`;

  return html;
}
export function getJohaisetsuCarPopupContentInnerHtml(car: JohaisetsuCarExt): string {
  const validWorkType = car.reportExt ? isValidWorkType(car.reportExt.johaisetsu_type, car.reportExt.work_type) : true;
  let html = '';
  if (validWorkType) {
    html = `<p class="title">【${car.car_name}】</p>`;
    html += `
      <p class="info-row">
        <span class="val">会社名:</span>
        <span class="val ml2">${car.johaisetsuCompanyName}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">作業班名:</span>
        <span class="val ml2">${car.user_johaisetsu_han_name}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">状態:</span>
        <span class="val ml2">${car.status_disp}</span>
      </p>`;
    if (!car.isMoving || !car.reportExt) {
      return html;
    }
    html += `
      <p class="info-row-wrap">
        <span >作業種別: ${car.reportExt.contentsDisp}</span>
      </p>`;
    if (car.reportExt.data_value) {
      html += `
          <p class="info-row-wrap">
            <span>散布回数: ${car.reportExt.data_value}回目</span>
          </p>`;
    }
    html += `
      <p class="info-row-wrap">
        <span >備考: ${car.reportExt.bikou1 ?? ''}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">基地出発時刻:</span>
        <span class="val ml2">${dtFormat(car.reportExt.base_departure_ts, 'HH:MM')}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">作業開始時刻:</span>
        <span class="val ml2">${dtFormat(car.reportExt.start_ts, 'HH:MM')}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">作業終了時刻:</span>
        <span class="val ml2">${dtFormat(car.reportExt.end_ts, 'HH:MM')}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">基地到着時刻:</span>
        <span class="val ml2">${dtFormat(car.reportExt.base_arrival_ts, 'HH:MM')}</span>
      </p>`;
    html += `
      <p class="btn-row">
        <button id="edit-johaisetsu-car-button" class="btn btn-sm btn-info">
          編集
        </button>
      </p>`;
  } else {
    if (!car.reportExt) return html;

    const reportData: JohaisetsuSettouReport = car.reportExt;
    html = `<p class="title">【${reportData.johaisetsu_han_name}】</p>`;
    html += `
      <p class="info-row">
        <span class="val">作業種別:</span>
        <span class="val ml2">${reportData.work_type}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">作業開始時刻:</span>
        <span class="val ml2">${dtFormat(reportData.base_departure_ts, 'yyyy/mm/dd HH:MM')}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">担当者名:</span>
        <span class="val ml2">${reportData.manager_name ?? ''}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="val">連絡先:</span>
        <span class="val ml2">${reportData.phone_number ?? ''}</span>
      </p>`;
  }
  return html;
}
export function getCleaningCarPopupContentInnerHtml(car: CleaningCarExt): string {
  let html = `<p class="title">【${car.car_name}】</p>`;
  html += `
    <p class="info-row">
      <span class="val">会社名:</span>
      <span class="val ml2">${car.cleaningCompanyName}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業班:</span>
      <span class="val ml2">${car.cleaning_han_name}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">状態:</span>
      <span class="val ml2">${car.status_disp}</span>
    </p>`;
  if (!car.isMoving || !car.reportExt) {
    return html;
  }

  html += `
    <p class="info-row-wrap">
      <span >作業内容: ${car.reportExt.contentsDisp}</span>
    </p>`;
  html += `
    <p class="info-row-wrap">
      <span>作業路線: ${car.reportExt.road_names}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">基地出発時刻:</span>
      <span class="val ml2">${dtFormat(car.reportExt.base_departure_ts, 'HH:MM')}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業開始時刻:</span>
      <span class="val ml2">${dtFormat(car.reportExt.cleaning_start_ts, 'HH:MM')}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業終了時刻:</span>
      <span class="val ml2">${dtFormat(car.reportExt.cleaning_end_ts, 'HH:MM')}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">基地到着時刻:</span>
      <span class="val ml2">${dtFormat(car.reportExt.base_arrival_ts, 'HH:MM')}</span>
    </p>`;
  return html;
}
export function getCleaningReportPhotoPopupContentInnerHtml(photo: CleaningReportPhotoExt): string {
  const photoTypeDisp = cleaningPhotoTypeOptions().find(e => e.value === photo.photo_type)?.text ?? '';
  let html =
    `<p class="title">【${photoTypeDisp}写真${photo.disp_order}】</p>`;
  html += _getCleaningReportInfoContentInnerHtml(photo);
  html += `
    <div class="image-area" id="cleaning-map-photo${photo.id}">
      <div class="image-box">
        <div class="image-wrap">
          <img src="${photo.savedImage}" class="image" />
        </div>
      </div>
    </div>`;
  return html;
}
export function getCleaningMtxPopupContentInnerHtml(mtx: CleaningMtxExt): string {
  return _getCleaningReportInfoContentInnerHtml(mtx);
}
function _getCleaningReportInfoContentInnerHtml(info: CleaningReportPhotoExt | CleaningMtxExt): string {
  let html = `

    <p class="info-row">
      <span class="val">会社名:</span>
      <span class="val ml2">${info.cleaningCompanyName}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業班:</span>
      <span class="val ml2">${info.cleaningHanName}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業日時:</span>
      <span class="val ml2">${dtFormat(info.ts)}</span>
    </p>`;
  html += `
    <p class="info-row-wrap">
      <span>作業内容: ${info.reportContents}</span>
    </p>`;
  html += `
    <p class="info-row-wrap">
      <span>作業路線: ${info.reportRoadNames}</span>
    </p>`;
  return html;
}
export function getJohaisetsuMtxPopupContentInnerHtml(mtx: JohaisetsuMtxExt): string {
  return _getJohaisetsuReportInfoContentInnerHtml(mtx);
}
function _getJohaisetsuReportInfoContentInnerHtml(info: JohaisetsuMtxExt): string {
  const contents = findTypeAndLabel(info.data_type);
  let html = `
    <p class="info-row">
      <span class="val">会社名:</span>
      <span class="val ml2">${info.johaisetsuCompanyName}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業班名:</span>
      <span class="val ml2">${info.johaisetsuHanName}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業日時:</span>
      <span class="val ml2">${dtFormat(info.ts)}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業種別:</span>
      <span class="val ml2">${contents}</span>
    </p>`;
  if (info.data_value && contents.startsWith('凍結防止作業')) {
    html += `
    <p class="info-row">
      <span class="val">散布回数:</span>
      <span class="val ml2">${info.data_value}回目</span>
    </p>`;
  }
  return html;
}

export function findTypeAndLabel(searchId: string | null): string {
  if (!searchId) {
    return '';
  }
  for (const workType of workTypes) {
    const item = workType.items.find(i => i.id === searchId);
    if (item) {
      return `${workType.type}(${item.label})`;
    }
  }
  return '';
}
