


import {
  defineComponent,
  computed,
  onMounted,
  watch, onUnmounted,
  reactive,
  ref,
  Ref,
  nextTick,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { Ability } from '@/models/apis/user/userResponse';
import RealtimeModePane from '@/components/Johaisetsu/JohaisetsuMap/components/RealtimeView/RealtimeModePane/index.vue';
import MapMiscInfo from '@/components/Johaisetsu/JohaisetsuMap/components/common/MapMiscInfo.vue';
import ManualDownloadLinks from '@/components/Johaisetsu/JohaisetsuMap/components/common/ManualDownloadLinks/index.vue';
import usePaneControl from '@/components/Johaisetsu/JohaisetsuMap/composables/usePaneControl';
import { useExtremeMap } from '@/components/Johaisetsu/JohaisetsuMap/composables/useExtremeMap';
import {
  CAR_LIST_MAX_HEIGHT_RATE,
  CAR_LIST_MIN_HEIGHT_RATE,
} from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import { useWaitSpinner } from '@/components/Johaisetsu/JohaisetsuMap/composables/useWaitSpinner';
import useJohaisetsuCars from '@/components/Johaisetsu/JohaisetsuMap/composables/useJohaisetsuCars';
import { getGeoItems } from '@/lib/geoItemHelper';
import { RoadNameDirection, DeselectFuncMap } from '@/models';
import { getJohaisetsuEnsuiPlants } from '@/lib/johaisetsu/johaisetsuEnsuiPlantHelper';
import {
  getGeoItemSearchTimestamps,
  getRoadDirectionFilteredGeoItemLayerData,
  calculatePaneWidths,
} from '@/lib/utils';
import { GeoItemSearchConds } from '@/models/geoItem';
import { OptByDataType } from '@/models/apis/geoItem/geoItemRequest';
import { RoadGeoItemData } from '@/models/apis/geoItem/geoItemResponse';
import { GetGeoItemsParamsRaw } from '@/models/route';
import { useComment } from '@/composables/useComment';
import { SettouPatrolReportExt } from '@/models/index';
import johaisetsuReportApi from '@/apis/johaisetsu/settouSagyouReport/johaisetsu_report';
import geoItemSearchComponents from '@/components/lib/geoItemSearchComponents';
import mapElemInfoComponents from '@/components/lib/mapElemInfoComponents';
import TabPaneComponent from '@/components/lib/TabPaneComponent/index.vue';
import GeoItemSearchComment from '@/components/lib/geoItemSearchComponents/GeoItemSearchComment.vue';
import {
  GeoItemLayerShow,
  GeoItemLayerOrder,
  ItemSearchConds,
  RedrawJohaisetsuCarLayerOptions,
  MiscInfoForCar,
} from '@/models/johaisetsu/johaisetsuMap';
import { initItemSearchConds } from '@/components/Johaisetsu/JohaisetsuMap/utils/index';
import {
  layers,
  sanpuNums,
  typeRemarks,
  isJohaisetsuCompanyDefaultUser,
  settouReportMappings,
  SETTOU_REPORT_LAYER_TYPE_NAME,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { JohaisetsuMtxExt } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';
import { useWeatherObservatory } from '@/composables/useWeatherObservatory';
import { JohaisetsuCarExt } from '@/models/johaisetsu/johaisetsuCar';

export default defineComponent({
  setup() {
    const store = useStore();
    const userState = store.state.user;
    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });
    const shouldShowManualDownloadLinks = computed<boolean>(() => {
      return isJohaisetsuCompanyDefaultUser(userState.johaisetsu_role) && !!mapViewState.extremeMapEssentials;
    });
    const { paneState, onChangeShowCarIcons: onChangeShowCarIconsOrig } = usePaneControl({
      paneStyleLimitMap: {
        listMinHeightRate: CAR_LIST_MIN_HEIGHT_RATE,
        listMaxHeightRate: CAR_LIST_MAX_HEIGHT_RATE,
      },
    });
    const {
      extremeMapRef,
      mapViewState,
      paneStyle,
      loadExtremeMapEssentials,
      forceResizeExtremeMap,
      redrawCarLayer,
      showJohaisetsuCarPopup,
      hidePopup,
      showJohaisetsuReportLayer,
      removeJohaisetsuReportLayer,
      showJohaisetsuMtxPopup,
      selectSettouReportsLayer,
      showSettouReportDetailPage,
      deselectAllSettouReports,
    } = useExtremeMap(paneState);
    const {
      carState,
      startCarUpdateInterval,
      stopCarUpdateInterval,
      selectCar,
      deselectCar,
      deselectAllCars,
      redrawCarLayerWatchValue,
    } = useJohaisetsuCars();
    const refMapSelectedElemInfoArea = ref<HTMLElement>();
    const refPaneCenter = ref<HTMLElement>();
    const refPaneRight = ref<HTMLElement>();
    const resizeMap = () => {
      if (!refMapSelectedElemInfoArea.value || !extremeMapRef.value) return;

      extremeMapRef.value.triggerResize();
    };
    const resizePanes = () => {
      if (!refPaneCenter.value || !refPaneRight.value) return;
      const windowWidth = window.innerWidth - 36;
      const showComment = commentState.geoItemMetaContext.show['comment'];
      const paneWidths = calculatePaneWidths(windowWidth, showComment);
      state.styles.paneSideMinWidth = paneWidths.paneSideMinWidth;
      state.styles.paneSideMaxWidth = paneWidths.paneSideMaxWidth;
      state.styles.paneCenterMinWidth = paneWidths.paneCenterMinWidth;
      state.styles.paneCenterMaxWidth = paneWidths.paneCenterMaxWidth;
      refPaneCenter.value.style.width = paneWidths.paneCenterInitialWidth;
      refPaneRight.value.style.width = paneWidths.paneRightSideInitialWidth;
      refPaneRight.value.style.display = showComment ? 'block' : 'none';

      nextTick(() => {
        resizeMap();
      });
    };
    const {
      state: commentState,
      ...commentUtils
    } = useComment({ resizeMap, resizePanes, refExtremeMap: extremeMapRef });
    watch(
      redrawCarLayerWatchValue,
      (value, oldValue) => {
        const fitToExtent = oldValue === 0;
        redrawCarLayer({ fitToExtent });
      },
    );
    const waitSpinnerState = useWaitSpinner();
    const state = reactive<ItemSearchConds>(initItemSearchConds());
    const deselectAllEnsuiPlants = () => {
      if (!extremeMapRef.value) return;
      extremeMapRef.value.deselectAllEnsuiPlants();
    };
    const deselectLayers = (obj: { excludes: Array<keyof DeselectFuncMap> }) => {
      Object.entries(deselectFuncMap).forEach(([k, func]) => {
        if (obj.excludes.includes(k as keyof DeselectFuncMap)) return;
        func();
      });
    };
    const { state: weatherObservatoryState, ...weatherObservatoryUtils } = useWeatherObservatory({
      deselectLayers,
      refExtremeMap: extremeMapRef,
      state: waitSpinnerState,
    });
    const deselectFuncMap: DeselectFuncMap = {
      cars: deselectAllCars,
      settouPatrolReports: deselectAllSettouReports,
      ensuiPlants: deselectAllEnsuiPlants,
      weatherObservatories: weatherObservatoryUtils.deselectAllWeatherObservatory,
    };
    const workingCarsCount = computed<number>(() => {
      return carState.cars.filter(car => car.isWorking).length;
    });
    const movingCarsCount = computed<number>(() => {
      return carState.cars.filter(car => car.isMoving).length;
    });
    const stoppedCarsCount = computed<number>(() => {
      return carState.cars.filter(car => !car.isMoving && !car.isWorking).length;
    });

    const doChangeGeoItemLayers = async(
      itemName: string,
      refGISearchComment: Ref<InstanceType<typeof GeoItemSearchComment>[]> | null,
    ) => {
      await commentUtils.changeGeoItemLayers(itemName, refGISearchComment);
      forceResizeExtremeMap();
    };
    const doRefreshGeoItemLayers = () => {
      commentUtils.refreshGeoItemLayers({spinnerMsg: commentState.waitSpinner.msgDefault});
    };
    const editJohaisetsuCar = (car: JohaisetsuCarExt) => {
      state.editingJohaisetsuCar = car;
    };
    const redrawJohaisetsuCarLayer = (options: RedrawJohaisetsuCarLayerOptions = {}) => {
      redrawCarLayer(options);
    };
    const onChangeShowCarIcons = () => {
      onChangeShowCarIconsOrig();
      redrawJohaisetsuCarLayer();
    };
    const getCarType = (carKind: string): MiscInfoForCar | null => {
      return typeRemarks[carKind] || null;
    };
    const onClickCar = async(selectedCar: JohaisetsuCarExt) => {
      state.selectCarKind = getCarType(selectedCar.johaisetsu_type);
      hidePopup();
      removeJohaisetsuReportLayer();
      // 選択中の車両をクリックした場合、選択を解除する
      if (deselectCar(selectedCar.id)) {
        redrawJohaisetsuCarLayer();
        return;
      }
      await selectCar(selectedCar.id);
      showJohaisetsuCarPopup(selectedCar);
      redrawJohaisetsuCarLayer({ setSelectedCarToCenter: true });
      if (!selectedCar.reportExt) return;
      showJohaisetsuReportLayer(selectedCar.reportExt, false);
    };
    const onAllDeselected = () => {
      hidePopup();
      redrawJohaisetsuCarLayer();
    };
    const paneResizeStopped = () => {
      forceResizeExtremeMap();
    };
    const onResize = () => {
      resizePanes();
      resizeLists();
      resizeMoviePlayerSize();
    };
    const resizeMoviePlayerSize = () => {
      const iWidth = window.innerWidth;
      const movieWHRatio = state.moviePlayerDefaultSize.movieH / state.moviePlayerDefaultSize.movieW;
      const defaultSpan = iWidth < 1000 ? 20 : 30;
      // 画面端両側の隙間と動画の間の隙間を除いて計算
      let defaultWidth =
        parseInt(((iWidth - (defaultSpan * (state.moviePlayerDialogMax + 1))) /
          state.moviePlayerDialogMax).toString());
      defaultWidth = Math.max(defaultWidth, state.moviePlayerDefaultSize.minW);
      const defaultHeight = parseInt((defaultWidth * movieWHRatio).toString()) +
        state.moviePlayerDefaultSize.controlBarH;
      state.moviePlayerDefaultSize.span = defaultSpan;
      state.moviePlayerDefaultSize.w = defaultWidth;
      state.moviePlayerDefaultSize.h = defaultHeight;
    };
    const resizeLists = () => {
      const headerH = 57;
      const h = window.innerHeight - headerH;
      state.styles.carListMinHeight = Math.floor(h * 0.20) + 'px';
      state.styles.carListMaxHeight = Math.floor(h * 0.38) + 'px';
      state.styles.tabContainerMinHeight = Math.floor(h * 0.49) + 'px';
      state.styles.tabContentMaxHeight = Math.floor(h * 2.00) + 'px';
    };
    const removeStallRiskPointsLayer = () => {
      if (!extremeMapRef.value) return;
      extremeMapRef.value.removeStallRiskPointsLayer();
    };
    const onClickMtx = (mtx: JohaisetsuMtxExt) => {
      hidePopup();
      mtx.isSelected = !mtx.isSelected;
      if (mtx.isSelected) {
        showJohaisetsuMtxPopup(mtx);
      }
      redrawJohaisetsuCarLayer();
    };
    const showStallRiskPointsLayer = () => {
      if (!extremeMapRef.value) return;
      extremeMapRef.value.showStallRiskPointsLayer();
    };
    const geoItemLayerData: Record<string, RoadGeoItemData | null> = {};
    const refreshGeoItemsLayer = async(
      itemOrder: GeoItemLayerOrder,
      geoItemSearchConds: GeoItemSearchConds,
      itemShow: GeoItemLayerShow,
      roadNameDirections: RoadNameDirection[],
    ) => {
      const reqDataTypes = [];
      const reqOptByDataType: Record<string, OptByDataType> = {};
      const dataTypeToReqDataType: Record<string, string> = {'snowfall': 'snowfall2'};
      const reqDataTypeToDataType: Record<string, string> = {};
      state.geoItemSearchConds = geoItemSearchConds;
      state.itemShow = itemShow;
      for (const [dataType, show] of Object.entries(itemShow)) {
        const item = layers.find(layer => layer.items.some(item => item.id === dataType));
        if (!show && extremeMapRef.value) {
          extremeMapRef.value.removeGeoItemsLayer(dataType);
          geoItemLayerData[dataType] = null;
          continue;
        }
        if (item?.type === SETTOU_REPORT_LAYER_TYPE_NAME) continue;

        const reqDataType = dataTypeToReqDataType[dataType] || dataType;
        reqDataTypeToDataType[reqDataType] = dataType;
        if (['sweeper_soukou', 'other_soukou', 'josetsu'].includes(dataType)) {
          reqOptByDataType[dataType] = { merge_leafs: false };
        }
        // あとでまとめてリクエスト
        reqDataTypes.push(reqDataType);
      }
      if (reqDataTypes.length === 0) {
        state.geoItemSearchTime.start = null;
        state.geoItemSearchTime.end = null;
        return;
      }
      waitSpinnerState.showWaitSpinner = true;
      const { startTs, endTs } = getGeoItemSearchTimestamps(state.geoItemSearchConds);
      const reqObj: GetGeoItemsParamsRaw = {
        startTs,
        endTs,
        dataTypes: reqDataTypes,
        optByDataType: reqOptByDataType,
      };
      reqObj.dataTypes = reqDataTypes;
      reqObj.optByDataType = reqOptByDataType;
      const resultMap = await getGeoItems(reqObj);
      waitSpinnerState.showWaitSpinner = false;
      state.geoItemSearchTime.start = reqObj.startTs;
      state.geoItemSearchTime.end = reqObj.endTs;
      for (const [reqDataType, layerData] of Object.entries(resultMap)) {
        const dataType = reqDataTypeToDataType[reqDataType];
        geoItemLayerData[dataType] = layerData;
        const filteredData = getRoadDirectionFilteredGeoItemLayerData(
          geoItemLayerData[dataType],
          roadNameDirections,
        );
        const dataTypeOrderKey = dataType as keyof GeoItemLayerOrder;
        const zIndexOffset = itemOrder[dataTypeOrderKey] || 0;
        if (extremeMapRef.value) {
          extremeMapRef.value.addGeoItemsLayer(dataType, filteredData, zIndexOffset);
        }
      }
    };
    const refreshLayers = async(
      itemOrder: GeoItemLayerOrder,
      geoItemSearchConds: GeoItemSearchConds,
      itemShow: GeoItemLayerShow,
      showEnsuiPlants: boolean,
      roadNameDirections: RoadNameDirection[],
    ) => {
      Promise.all([
        refreshGeoItemsLayer(itemOrder, geoItemSearchConds, itemShow, roadNameDirections),
        toggleSettouReportsLayer(itemShow, geoItemSearchConds, true),
        toggleEnsuiPlantsLayer(showEnsuiPlants),
      ]);
    };
    const roadNameDirectionListChanged = (
      itemOrder: GeoItemLayerOrder,
      roadNameDirections: RoadNameDirection[],
    ) => {
      for (const [dataType, data] of Object.entries(geoItemLayerData)) {
        if (!data || !extremeMapRef.value) { continue; }
        extremeMapRef.value.removeGeoItemsLayer(dataType);
        const filteredData = getRoadDirectionFilteredGeoItemLayerData(data, roadNameDirections);
        const dataTypeKey = dataType as keyof GeoItemLayerOrder;
        const zIndexOffset = itemOrder[dataTypeKey] || 0;
        extremeMapRef.value.addGeoItemsLayer(dataType, filteredData, zIndexOffset);
      }
    };
    const toggleSettouReportsLayer = async(
      itemShow: GeoItemLayerShow,
      geoItemSearchConds: GeoItemSearchConds,
      isRefreshLayers = false,
    ) => {
      state.geoItemSearchConds = geoItemSearchConds;
      const changedReports: string[] = [];
      settouReportMappings.forEach(({ itemKey, stateKey, displayName }) => {
        if (itemShow[itemKey] !== state.settouReportLayerShow[stateKey] || isRefreshLayers) {
          state.settouReportLayerShow[stateKey] = itemShow[itemKey];
          changedReports.push(displayName);
        }
      });
      if (changedReports.length > 0) {
        const reqObj = getGeoItemSearchTimestamps(state.geoItemSearchConds);
        state.geoItemSearchTime.start = reqObj.startTs;
        state.geoItemSearchTime.end = reqObj.endTs;
        waitSpinnerState.showWaitSpinner = true;
        await selectSettouReportsLayer(itemShow, state.geoItemSearchConds, abilityMap.value, changedReports);
        waitSpinnerState.showWaitSpinner = false;
      }
    };
    const toggleEnsuiPlantsLayer = async(showEnsuiPlants: boolean) => {
      if (!extremeMapRef.value) return;
      const currentDataMap: Record<string, SettouPatrolReportExt> = {
        ...extremeMapRef.value.getEnsuiPlantMap(),
      };
      if (showEnsuiPlants) {
        waitSpinnerState.showWaitSpinner = true;
        const reqObj = getGeoItemSearchTimestamps(state.geoItemSearchConds);
        const data = await getJohaisetsuEnsuiPlants(reqObj);
        waitSpinnerState.showWaitSpinner = false;
        state.geoItemSearchTime.start = reqObj.startTs;
        state.geoItemSearchTime.end = reqObj.endTs;
        data.forEach(e => {
          const current = currentDataMap[e.id];
          e.isSelected = current?.isSelected ?? false;
        });
        extremeMapRef.value.showEnsuiPlantLayer(data);
      } else {
        const isSelected = Object.values(currentDataMap).some(e => e.isSelected);
        if (isSelected) {
          extremeMapRef.value.hidePopup();
        }
        extremeMapRef.value.removeEnsuiPlantLayer();
      }
    };
    const updateJohaisetsuCar = async() => {
      if (!state.editingJohaisetsuCar) return;
      waitSpinnerState.showWaitSpinner = true;
      try {
        const carObj = state.editingJohaisetsuCar;
        const reqObj = {
          johaisetsu_car_id: carObj.id,
          work_type: carObj.report?.work_type ?? '',
          data_value: carObj.report?.data_value ? carObj.report?.data_value.toString() : '',
          bikou1: carObj.report?.bikou1 ?? '',
        };
        await johaisetsuReportApi.updateContent(
          reqObj,
        );
        await startCarUpdateInterval();
        await onClickCar(carObj);
      } catch (e) {
        console.log(e);
      } finally {
        state.editingJohaisetsuCar = null;
        waitSpinnerState.showWaitSpinner = false;
      }
    };
    onMounted(async() => {
      onResize();
      window.addEventListener('resize', onResize);
      waitSpinnerState.showWaitSpinner = true;
      await Promise.all([loadExtremeMapEssentials(), startCarUpdateInterval()]);
      redrawJohaisetsuCarLayer({ fitToExtent: true });
      waitSpinnerState.showWaitSpinner = false;
    });
    onUnmounted(() => {
      stopCarUpdateInterval();
      window.removeEventListener('resize', onResize);
    });

    return {
      mapViewState,
      paneState,
      paneStyle,
      carState,
      state,
      commentState,
      commentUtils,
      weatherObservatoryState,
      extremeMapRef,
      refMapSelectedElemInfoArea,
      refPaneCenter,
      refPaneRight,
      sanpuNums,
      movingCarsCount,
      shouldShowManualDownloadLinks,
      workingCarsCount,
      stoppedCarsCount,
      editJohaisetsuCar,
      getCarType,
      doChangeGeoItemLayers,
      doRefreshGeoItemLayers,
      onClickCar,
      onClickMtx,
      onAllDeselected,
      onChangeShowCarIcons,
      paneResizeStopped,
      removeStallRiskPointsLayer,
      refreshGeoItemsLayer,
      refreshLayers,
      roadNameDirectionListChanged,
      redrawJohaisetsuCarLayer,
      showStallRiskPointsLayer,
      showSettouReportDetailPage,
      toggleEnsuiPlantsLayer,
      toggleSettouReportsLayer,
      updateJohaisetsuCar,
      ...weatherObservatoryUtils,
    };
  },
  components: {
    RealtimeModePane,
    ManualDownloadLinks,
    MapMiscInfo,
    ...mapElemInfoComponents,
    ...geoItemSearchComponents,
    TabPaneComponent,
  },
});
