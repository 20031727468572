import { packToTimeInteger } from '@/lib/dateTimeUtil';
import numeral from 'numeral';
import { KindChoiceWithKey, RoadNameDirection } from '@/models';
import { GeoItemSearchConds } from '@/models/geoItem';
import { PlaceGeoItemData, RoadGeoItemData } from '@/models/apis/geoItem/geoItemResponse';
import { PaneWidths } from '@/models/index';

export const lineBreakToBR = (str: string | null): string => {
  if (!str) { return ''; }

  return str
    .replace(/\r\n|\\r\\n/g, '<br>')
    .replace(/\r|\\r/g, '<br>')
    .replace(/\n|\\n/g, '<br>');
};

export const splitByLineBreak = (str: string | null): string[] => {
  if (!str) { return []; }
  const token = '#@%;#';
  return str
    .replace(/\r\n|\\r\\n/g, token)
    .replace(/\r|\\r/g, token)
    .replace(/\n|\\n/g, token)
    .split(token);
};

export function convertStrToTime(hour?: string | null, minute?: string | null): number | null {
  if (!hour || !minute) {
    return null;
  } else {
    return packToTimeInteger(parseInt(hour), parseInt(minute), 0);
  }
}

export function convertStrToNumber(str?: string | null): number | null {
  if (!str) { return null; }
  const someInt = parseInt(str);
  return isNaN(someInt) ? 0 : someInt;
}

export function setValue<T>(obj: T, key: keyof T, value: T[keyof T]): void {
  obj[key] = value;
}

export const numberFormat = (val: number, format: string): string => {
  return numeral(val).format(format);
};

export const johaisetsuKpDisp1 = (kpVal: string, format = '0.0[0]'): string => {
  return numeral(kpVal).format(format);
};

export const getInitTimeChoices = (): KindChoiceWithKey[] => {
  let arr = [
    {key: 0, val: '現在'},
    {key: -900 * 1000, val: '15分前'},
    {key: -1800 * 1000, val: '30分前'},
  ];
  const nums = Array.from({length: 72}, (e, i) => i + 1);
  arr = arr.concat(nums.map(num => {
    return {key: -3600 * 1000 * num, val: `${num}時間前`};
  }));
  return arr;
};

interface GeoItemSearchTimestamps {
  startTs: Date;
  endTs: Date;
}
export const getGeoItemSearchTimestamps = (geoItemSearchConds: GeoItemSearchConds): GeoItemSearchTimestamps => {
  const now = new Date().valueOf();
  let startTs = new Date(now + geoItemSearchConds.timeFromOffset);
  let endTs = new Date(now + geoItemSearchConds.timeToOffset);
  // どっちが前でも許容する
  if (startTs > endTs) {
    [startTs, endTs] = [endTs, startTs];
  }
  return { startTs, endTs };
};

export const getRoadDirectionFilteredGeoItemLayerData = (
  data: RoadGeoItemData | null,
  roadNameDirections: RoadNameDirection[],
): Record<string, PlaceGeoItemData> => {
  const selectedRDs: string[][] = [];
  roadNameDirections.forEach(e1 => {
    if (e1.isDummy) { return; }
    e1.directions.forEach(e2 => {
      if (e2.selected) {
        selectedRDs.push([e1.roadNameReal, e2.direction]);
      }
    });
  });
  const ret: Record<string, PlaceGeoItemData> = {};
  if (data) {
    const dataRDs = Object.keys(data);
    selectedRDs.forEach(key => {
      const selectedRoadNameDisp = key[0];
      const selectedDirection = key[1];
      const filteredDataRDs = dataRDs.filter(e => {
        const tmpSplit = e.split('#');
        const roadName = tmpSplit[0];
        const direction = tmpSplit[1];
        // roadNameDispはroadNameのprefixとなっているはず
        return roadName.indexOf(selectedRoadNameDisp) === 0 && direction === selectedDirection;
      });

      filteredDataRDs.forEach(key => {
        ret[key] = data[key];
      });
    });
  }
  return ret;
};

export function calculatePaneWidths(windowWidth: number, showComment: boolean): PaneWidths {
  const paneSideHardMaxWidth = 380;
  const paneCenterHardMinWidth = windowWidth - 380 * 2;

  const paneWidths = {
    paneSideMinWidth: Math.min(Math.floor(windowWidth * 0.20), paneSideHardMaxWidth) + 'px',
    paneSideMaxWidth: Math.min(Math.floor(windowWidth * 0.30), paneSideHardMaxWidth) + 'px',
    paneCenterMinWidth: Math.max(Math.floor(windowWidth * 0.40), paneCenterHardMinWidth) + 'px',
    paneCenterMaxWidth: Math.max(Math.floor(windowWidth * 0.80), paneCenterHardMinWidth) + 'px',
    paneSideInitialWidth: Math.floor(windowWidth * 0.21) + 'px',
    paneCenterInitialWidth: Math.floor(windowWidth * 0.81) + 'px',
    paneRightSideInitialWidth: '0px',
  };

  if (showComment) {
    paneWidths.paneCenterMaxWidth = Math.max(Math.floor(windowWidth * 0.60), paneCenterHardMinWidth) + 'px';
    paneWidths.paneRightSideInitialWidth = paneWidths.paneSideInitialWidth;
    paneWidths.paneCenterInitialWidth = Math.floor(windowWidth * 0.60) + 'px';
  }

  return paneWidths;
}
