import { extension as convertMimeTypeToExtension, lookup as lookupMimeTypes } from 'mime-types';
import { BoardEntryEdit } from '@/models/settouMessageBoard';
import { editingBoardEntryDefault } from '@/components/SettouMessageBoard/consts/board_entry_edit_modal';
import { BoardTab } from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import { boardTabDefault } from '@/components/SettouMessageBoard/consts/settou_message_board';

export const ONE_DAY_MSEC = 1000 * 60 * 60 * 24;
export const BOARD_ENTRY_ATTACHED_TO_CONTENT = 'content';
export const BOARD_ENTRY_ATTACHED_TO_REPLY = 'reply';
export interface SearchParams {
  dateFrom: Date;
  dateTo: Date;
  han?: number | null;
  honshaReportNeeded?: number | null;
  reportToHonsha?: number | null;
  companyName?: string | null;
}

export const convSearchParamDateFromTo = (dateFrom: Date, dateTo: Date): [Date, Date] => {
  return [dateFrom, new Date(dateTo.getTime() + ONE_DAY_MSEC - 1)];
};

export const fileTypeIconFromFilePath = (filePath: string): string => {
  const mimeType = lookupMimeTypes(filePath);
  if (!mimeType) return 'fa-file';
  const extention = convertMimeTypeToExtension(mimeType);
  if (!extention) return 'fa-file';
  if (extention === 'pdf') return 'fa-file-pdf';
  if (extention === 'doc' || extention === 'docx') return 'fa-file-word';
  if (extention === 'xls' || extention === 'xlsx') return 'fa-file-excel';
  if (extention === 'ppt' || extention === 'pptx') return 'fa-file-powerpoint';
  return 'fa-file';
};

export const isImageMimeType = (mimeType: string): boolean => {
  return mimeType.startsWith('image/');
};

export const getEditingBoardEntryDefault = (): BoardEntryEdit => {
  return JSON.parse(JSON.stringify(editingBoardEntryDefault));
};

export const getBoardTabDefault = (): BoardTab => {
  return { ...boardTabDefault };
};
