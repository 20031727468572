import {
  JOHAISETSU_ROLE_EAST,
  JOHAISETSU_ROLE_KANAGAWA,
  JOHAISETSU_ROLE_WEST,
} from '@/consts/johaisetsu';
import { BoardTab } from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';

export const BOARD_ROLE_CREATE = 10;
export const BOARD_ROLE_REPLY = 20;

export const johaisetsuRoleMap: Record<string, number> = {
  [JOHAISETSU_ROLE_WEST]: 20,
  [JOHAISETSU_ROLE_EAST]: 30,
  [JOHAISETSU_ROLE_KANAGAWA]: 40,
};
export const departmentDispMap: Record<string, string> = {
  'west': '西局本部',
  'east': '東局本部',
  'kanagawa': '神局本部',
};
export const boardTabDefault: BoardTab = {
  id: 0,
  disp_name: '',
  board_kind: '',
  department: '',
  owner_company_name: '',
  disp_order: 999,
  johaisetsu_role: '',
  board_role: 99,
};
