


import {
  ref,
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  onUnmounted,
  watch,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import PrintPagination from '@/components/lib/PrintPagination/index.vue';
import { convReport, convSearchParamTsTo, loadCleaningMtxs } from '@/components/CleaningMap/utils/index';
import { cleaningPhotoTypeOptions } from '@/components/CleaningMap/consts/cleaning_photo';
import { Settings as UserSettings } from '@/models/apis/user/userResponse';
import { CleaningReport,
  CleaningReportExt,
  CleaningReportPhotoExt,
} from '@/models/apis/cleaning/cleaningReportResponse';
import { ExtremeMapEssentials } from '@/models/cleaningMap';
import cleaningReportApi from '@/apis/cleaning_report';
import { waitForCleaningMasters } from '@/lib/cleaningHelper';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { dtFormat, dateStrToDate } from '@/lib/dateHelper';
import { setPrintPageSize } from '@/lib/printPageHelper';
import { fetchImageAsObjectURL } from '@/lib/imageHelper';
import { CLEANING_REPORT_MAX_MTXS } from '@/components/CleaningMap/consts/cleaning_map';
import {
  PHOTO_HEADER_DIVIDING_MARK,
  PHOTO_NUM_LIMIT_PER_PAGE,
  CURRENT_PAGE_DEFAULT,
  ITEMS_PER_PAGE_DEFAULT,
  ITEMS_PER_PAGE_LIMIT,
} from '@/consts/report_print';

interface CleaningPrintReport extends CleaningReportExt {
  reportPhotos: Record<string, CleaningReportPhotoExt[]>;
  isMapAvailable: boolean;
}
interface PrintCleaningReportState {
  isReady: boolean;
  reports: CleaningReportExt[];
  paginatedReports: CleaningPrintReport[];
  extremeMapEssentials: ExtremeMapEssentials | null;
  inputItemsPerPage: string;
}
export default defineComponent({
  name: 'PrintCleaningReport',

  setup() {
    const state = reactive<PrintCleaningReportState>({
      isReady: false,
      reports: [],
      paginatedReports: [],
      extremeMapEssentials: null,
      inputItemsPerPage: ITEMS_PER_PAGE_DEFAULT.toString(),
    });
    const shouldShowPagination = computed<boolean>(() => {
      return state.reports.length > ITEMS_PER_PAGE_DEFAULT;
    });
    const store = useStore();
    const userState = store.state.user;
    const userSettings = computed<UserSettings>(() => {
      return userState.settings;
    });
    const cleaningPhotoTypes = cleaningPhotoTypeOptions();
    const { route, router } = useRoute();
    const cleaningReportId = computed<string>(() => {
      return route.value.params.cleaningReportId;
    });
    const currentPage = computed<number>(() => {
      const currentPage = Number(route.value.params.currentPage);
      const isNumeric = !isNaN(currentPage);
      return isNumeric ? currentPage : CURRENT_PAGE_DEFAULT;
    });
    const itemsPerPage = computed<number>(() => {
      const itemsPerPage = Number(route.value.params.itemsPerPage);
      const isNumeric = !isNaN(Number(itemsPerPage));
      if (!isNumeric || itemsPerPage < CURRENT_PAGE_DEFAULT) return ITEMS_PER_PAGE_DEFAULT;
      // 1ページあたりの表示件数が上限を超えている場合は上限値を設定
      return Math.min(itemsPerPage, ITEMS_PER_PAGE_LIMIT);
    });
    const reportDtFormat = (dt: Date) => {
      return dtFormat(dt, 'yyyy年m月d日 HH時MM分');
    };
    const extremeMap = ref<InstanceType<typeof ExtremeMap>[]>([]);
    const convPrintReport = async(reports: CleaningReportExt[]): Promise<CleaningPrintReport[]> => {
      await loadCleaningMtxs(reports, CLEANING_REPORT_MAX_MTXS);
      const printReport = reports.map(report => {
        report.photosExt.forEach(async(photo) => {
          photo.savedImage = await fetchImageAsObjectURL(photo.image_path);
        });

        return {
          ...report,
          reportPhotos: convReportPhotos(report.photosExt),
          isMapAvailable: false,
        };
      });
      return printReport;
    };
    const convReportPhotos = (photos: CleaningReportPhotoExt[]): Record<string, CleaningReportPhotoExt[]> => {
      const reportPhotos: Record<string, CleaningReportPhotoExt[]> = {};
      cleaningPhotoTypes.forEach(cleaningPhotoType => {
        const reportTypePhotos = photos.filter(photo => photo.photo_type === cleaningPhotoType.value);
        if (reportTypePhotos.length === 0) {
          return;
        }
        for (let i = 0; i <= reportTypePhotos.length / (PHOTO_NUM_LIMIT_PER_PAGE + 1); i++) {
          reportPhotos[i + PHOTO_HEADER_DIVIDING_MARK + cleaningPhotoType.text] = reportTypePhotos.slice(i * PHOTO_NUM_LIMIT_PER_PAGE, (i + 1) * PHOTO_NUM_LIMIT_PER_PAGE);
        }
      });
      return reportPhotos;
    };
    const getPhotoType = (photoHeaderInfo: string): string => {
      return photoHeaderInfo.split(PHOTO_HEADER_DIVIDING_MARK)[1];
    };
    const searchReport = async(): Promise<CleaningReport[]> => {
      if (cleaningReportId.value) {
        const { data } = await cleaningReportApi.get(parseInt(cleaningReportId.value));
        return [ data ];
      }

      const cleaningCompanyIds = route.value.params.cleaningCompanyIds;
      const cleaningHanName = route.value.params.cleaningHanName;
      const searchParam = {
        ts_from: dateStrToDate(route.value.params.dateFrom) || new Date(),
        ts_to: convSearchParamTsTo(dateStrToDate(route.value.params.dateTo) || new Date()),
        cleaning_company_ids: cleaningCompanyIds === 'all' ? [] : cleaningCompanyIds.split(',').map(e => { return parseInt(e); }),
        cleaning_han_name: cleaningHanName === 'all' ? '' : cleaningHanName,
      };
      const ret = await cleaningReportApi.index(searchParam);
      return ret.data;
    };
    watch(() => extremeMap.value, () => {
      if (!extremeMap.value) {
        return;
      }
      showCleaningReport();
    });
    const showCleaningReport = () => {
      const h = window.innerHeight - 300;
      for (let i = 0; i < state.paginatedReports.length; i++) {
        // extremeMap.valueでwatchすると、地図を表示し終わったものも含まれるため、
        // 地図を表示し終わったものは処理せず再描画されるのを防ぐ
        if (!extremeMap.value[i] || state.paginatedReports[i].isMapAvailable) {
          continue;
        }
        extremeMap.value[i].setMapHeight(h);
        extremeMap.value[i].triggerResize();
        extremeMap.value[i].removeCleaningReportLayer();
        extremeMap.value[i].showCleaningReportLayer(state.paginatedReports[i], {
          hideCleaningPhotoIcons: false,
          hideDefectPhotoIcons: false,
          fitToExtent: true,
        });
        state.paginatedReports[i].isMapAvailable = true;
      }
    };
    const paginatePrintReport = async() => {
      const nextPageIdx = currentPage.value * itemsPerPage.value;
      const minIdx = nextPageIdx - itemsPerPage.value;
      const reports = state.reports.slice(minIdx, nextPageIdx);
      state.paginatedReports = await convPrintReport(reports);
    };
    const moveToSelectedPage = (currentPage: number, itemsPerPage: number) => {
      // ページ切り替え時に地図が表示されなくなる問題を回避するため、リダイレクトしてページングする
      const routeObj = {
        name: 'PrintCleaningReportsAll',
        params: {
          dateFrom: route.value.params.dateFrom,
          dateTo: route.value.params.dateTo,
          cleaningCompanyIds: route.value.params.cleaningCompanyIds,
          cleaningHanName: route.value.params.cleaningHanName,
          currentPage: currentPage.toString(),
          itemsPerPage: itemsPerPage.toString(),
        },
      };
      const obj = router.resolve(routeObj);
      window.location.href = obj.href;
    };
    const onClickPageChange = (pageNum: number) => {
      moveToSelectedPage(pageNum, itemsPerPage.value);
    };
    const onClickItemsPerPageChange = (itemsPerPage: number) => {
      moveToSelectedPage(CURRENT_PAGE_DEFAULT, itemsPerPage);
    };
    onMounted(async() => {
      document.body.classList.add('A4');
      setPrintPageSize('A4 portrait');

      await Promise.all([waitForUserAndMasters(), waitForCleaningMasters()]);

      state.extremeMapEssentials = {
        userSettings: userSettings.value,
        kpMap: window.master.kpMap,
      };
      state.inputItemsPerPage = itemsPerPage.value.toString();

      const reports = await searchReport();
      state.reports = reports.map(e =>
        convReport(e, window.cleaningMaster.cleaningCompanies),
      );
      await paginatePrintReport();

      state.isReady = true;
    });
    onUnmounted(() => {
      document.body.classList.remove('A4');
    });

    return {
      ...toRefs(state),
      // ref
      extremeMap,
      // computed
      shouldShowPagination,
      currentPage,
      itemsPerPage,
      reportDtFormat,
      dtFormat,
      getPhotoType,
      onClickPageChange,
      onClickItemsPerPageChange,
    };
  },
  components: {
    PrintPagination,
  },
});
