import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import {
  JohaisetsuSettouOnsiteManagementCreateParams,
  JohaisetsuSettouOnsiteManagementUpdateParams,
  JohaisetsuSettouOnsiteManagementStartUpdateParams,
  JohaisetsuSettouOnsiteManagementEndUpdateParams,
  JohaisetsuSettouOnsiteManagementBaseArrivalUpdateParams,
  JohaisetsuSettouOnsiteManagementGetCurrentParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementRequest';
import {
  JohaisetsuSettouOnsiteManagementResponse,
  SettouOnsiteManagementReportShowResponse,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementResponse';
import { SettouReportsGetByCarIdsParams } from '@/models/apis/johaisetsu/settouPatrol/johaisetsuSettouPatrolRequestCommon';

export default {
  create(
    params: JohaisetsuSettouOnsiteManagementCreateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_onsite_management'), params);
  },
  update(
    params: JohaisetsuSettouOnsiteManagementUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_onsite_management/update'), params);
  },
  updateStartTime(
    params: JohaisetsuSettouOnsiteManagementStartUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_onsite_management/update_start_time'), params);
  },
  updateEndTime(
    params: JohaisetsuSettouOnsiteManagementEndUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_onsite_management/update_end_time'), params);
  },
  updateBaseArrivalTime(
    params: JohaisetsuSettouOnsiteManagementBaseArrivalUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_onsite_management/update_base_arrival_time'), params);
  },
  getCurrent(
    params: JohaisetsuSettouOnsiteManagementGetCurrentParams,
  ): Promise<AxiosResponse<JohaisetsuSettouOnsiteManagementResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_settou_onsite_management/current'), { params });
  },
  getByCarIds(
    params: SettouReportsGetByCarIdsParams,
  ): Promise<AxiosResponse<SettouOnsiteManagementReportShowResponse[]>> {
    return axios.get(utils.getApiUrl('johaisetsu_settou_onsite_management/get_by_car_ids'), { params });
  },
};
