import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import {
  JohaisetsuReportCreateParams,
  JohaisetsuReportJohaisetsuStartUpdateParams,
  JohaisetsuReportJohaisetsuEndUpdateParams,
  JohaisetsuReportBaseArrivalUpdateParams,
  JohaisetsuReportContentAndRoadParams,
  JohaisetsuReportIndexParams,
  JohaisetsuReportGetCurrentParams,
  JohaisetsuReportGetByCarIdsParams,
} from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportRequest';
import {
  JohaisetsuReportIndexResponse,
  JohaisetsuReportCreateOrUpdateResponse,
} from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';

export default {
  index(params: JohaisetsuReportIndexParams): Promise<AxiosResponse<JohaisetsuReportIndexResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_reports'), {
      params: params || {},
    });
  },
  createJohaisetsuReport(
    params: JohaisetsuReportCreateParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_reports'), params);
  },
  updateStartTime(
    params: JohaisetsuReportJohaisetsuStartUpdateParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_report/update_start_time'), params);
  },
  updateEndTime(
    params: JohaisetsuReportJohaisetsuEndUpdateParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_report/update_end_time'), params);
  },
  updateBaseArrivalTime(
    params: JohaisetsuReportBaseArrivalUpdateParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_report/update_base_arrival_time'), params);
  },
  updateContent(
    params: JohaisetsuReportContentAndRoadParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_report/update_content'), params);
  },
  get(id: number): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_reports/${id}`));
  },
  getCurrent(
    params: JohaisetsuReportGetCurrentParams,
  ): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_reports/current'), { params });
  },
  getByCarIds(params: JohaisetsuReportGetByCarIdsParams): Promise<AxiosResponse<JohaisetsuReportCreateOrUpdateResponse[]>> {
    return axios.get(utils.getApiUrl('/get_by_car_ids'), { params });
  },
};
