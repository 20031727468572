import { reactive } from '@vue/composition-api';
import { PhotoModalState } from '@/models/photoModal';
import { CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { dtFormat } from '@/lib/dateHelper';

export interface UsePhotoModalResult {
  photoModalState: PhotoModalState;
  showPhotoModal: (photoType: string, photos: CleaningReportPhotoExt[]) => void;
  hidePhotoModal: () => void;
}
export function usePhotoModal(): UsePhotoModalResult {
  const photoModalState = reactive<PhotoModalState>({
    shouldShowPhotoModal: false,
    title: '',
    photos: [],
  });

  const showPhotoModal = (photoType: string, photos: CleaningReportPhotoExt[]) => {
    photoModalState.title = photoType + '写真';
    photoModalState.photos = photos.map((photo) => ({
      id: photo.id,
      photoTitle: photo.disp_order + '. ' + dtFormat(photo.ts),
      savedImage: photo.savedImage,
    }));
    photoModalState.shouldShowPhotoModal = true;
  };
  const hidePhotoModal = () => {
    photoModalState.shouldShowPhotoModal = false;
  };

  return {
    photoModalState,
    showPhotoModal,
    hidePhotoModal,
  };
}
