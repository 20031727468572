
import shareScopeConsts from '@/consts/share_scope';
import {
  getNearKps,
  getLocationDispOfKp,
  validateAngle,
  validateComment,
  convertFilesToFileObjs,
} from '@/lib/commentHelper';
import { fetchFileAsObjectInfo } from '@/lib/fileHelper';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { MapElemInfo, CandidateFile } from '@/models';
import { GIKilopost } from '@/models/geoItem';
import { Comment } from '@/models/apis/comment/commentResponse';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';
import useMaster from '@/composables/useMaster';
import { splitByLineBreak } from '@/lib/utils';
import { dtFormat } from '@/lib/dateHelper';
import { downloadObjectUrl } from '@/lib/downloadHelper';
import { UserState } from '@/store/modules/user';

interface EditComment extends Comment {
  update_ts?: boolean;
  files?: Blob[];
  remove_file_flag?: boolean;
}

interface MapElemInfoCommentState {
  mode: 'show' | 'edit';
  user: UserState;
  // 付箋に保存済みの画像を保持する
  savedFile: string | null;
  savedFileType: string | null;
  savedFileName: string | null;
  errorMsgs: string[];
  comment: Comment;
  // 動画画面でプレイヤーから切り出して渡される画像またはファイル選択の画像を保持する
  candidateFiles: CandidateFile[];
  update_ts: boolean;
  selectedKpObj: GIKilopost;
  editItem: EditComment;
}

interface MapElemInfoCommentElement extends MapElemInfo<Comment> {
  candidateImages: Blob[];
}

const DEFAULT_SHARE_SCOPE = shareScopeConsts.SHARE_SCOPE_GROUP3;

export default defineComponent({
  name: 'map-elem-info-comment',
  props: {
    isCreateSingleNewElement: {
      type: Boolean,
      default: false,
    },
    elem: {
      type: Object as PropType<MapElemInfoCommentElement>,
      required: true,
    },
    forceShowCancelButton: {
      type: Boolean,
      default: false,
    },
    updateFailed: {
      type: Number,
      default: 0,
    },
    createFailed: {
      type: Number,
      default: 0,
    },
    showMoveButtons: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MapElemInfoCommentState>({
      mode: 'show',
      user: {} as UserState,
      savedFile: null,
      savedFileType: null,
      savedFileName: null,
      errorMsgs: [],
      comment: {} as Comment,
      candidateFiles: [],
      update_ts: false,
      selectedKpObj: {} as GIKilopost,
      editItem: {} as EditComment,
    });

    const startEdit = () => {
      state.mode = 'edit';
      state.update_ts = false;
      state.candidateFiles = [];
      state.selectedKpObj = commentKpObject.value ?? nearKps.value.length > 0 ? nearKps.value[0] : {} as GIKilopost;
      if (refUploadFile.value) {
        refUploadFile.value.value = '';
      }
      const editItem = Object.assign({}, state.comment);
      editItem.comment_type = editItem.comment_type || 'share';
      editItem.share_scope = editItem.share_scope || DEFAULT_SHARE_SCOPE;
      editItem.content = splitByLineBreak(editItem.content).join('\n');
      if (state.selectedKpObj && (editItem.angle === null || editItem.angle === undefined)) {
        editItem.angle = state.selectedKpObj.angle;
      }
      // 候補画像と保存済画像が同時に表示することがない
      if (props.elem.candidateImages) {
        props.elem.candidateImages.forEach((image, idx) => {
          if (!image) {
            return;
          }
          state.candidateFiles.push({
            id: idx,
            content: convertBlobToImageSrc(image),
            type: image.type,
            name: '',
            selected: false,
          });
        });
        // 1枚だったら選択しておく
        if (state.candidateFiles.length === 1) {
          state.candidateFiles[0].selected = true;
        }
      } else if (state.savedFile && state.savedFileType) {
        state.candidateFiles = [{
          id: 0,
          content: state.savedFile,
          type: state.savedFileType,
          name: state.savedFileName ?? '',
          selected: true,
        }];
      }
      state.editItem = editItem;
      emit('component-resized');
    };
    const initDisplay = async() => {
      releaseSavedFile();
      releaseCandidateFiles();
      state.errorMsgs = [];
      state.comment = props.elem.data || {};
      if (props.isCreateSingleNewElement) {
        startEdit();
      } else {
        state.mode = 'show';
      }
      if (!state.comment.comment_files || state.comment.comment_files.length === 0) {
        return;
      }
      const savedFileInfo = await fetchFileAsObjectInfo(state.comment.comment_files[0].file_path);
      if (savedFileInfo) {
        state.savedFile = savedFileInfo.url;
        state.savedFileType = savedFileInfo.type;
        state.savedFileName = state.comment.comment_files[0].file_name;
        emit('component-resized');
      }
    };
    const { state: msts } = useMaster();
    watch(() => msts.user, () => {
      state.user = msts.user;
    });
    onMounted(initDisplay);
    const releaseSavedFile = () => {
      if (!state.savedFile) {
        return;
      }
      URL.revokeObjectURL(state.savedFile);
      state.savedFile = null;
    };
    const releaseCandidateFiles = () => {
      state.candidateFiles = state.candidateFiles.filter(file => file.content !== state.savedFile);
      state.candidateFiles.forEach(elm => URL.revokeObjectURL(elm.content));
      state.candidateFiles = [];
    };
    onBeforeUnmount(() => {
      releaseSavedFile();
      releaseCandidateFiles();
    });

    const refUploadFile = ref<HTMLInputElement>();
    const convertBlobToImageSrc = (blob: Blob) => {
      return URL.createObjectURL(blob);
    };

    watch(() => props.elem, initDisplay);
    const createFailedMsg = '付箋追加に失敗しました。';
    const updateFailedMsg = '付箋更新に失敗しました。';
    watch(() => props.updateFailed, () => {
      if (!state.errorMsgs.includes(updateFailedMsg)) {
        state.errorMsgs.push(updateFailedMsg);
      }
    });
    watch(() => props.createFailed, () => {
      if (!state.errorMsgs.includes(createFailedMsg)) {
        state.errorMsgs.push(createFailedMsg);
      }
    });

    // computeds
    const nearKpThresholdMeters = 100;
    const nearKps = computed(() => {
      return getNearKps(
        {lat: props.elem.data.lat, lon: props.elem.data.lon},
        msts.kpMap,
        msts.roadNameDispMap,
        nearKpThresholdMeters,
      );
    });
    const commentKpObject = computed(() => {
      if (!props.elem.data?.kp_uid) { return null; }
      return nearKps.value.find(e => e.kp_uid === props.elem.data.kp_uid);
    });
    const iconPath = computed(() => {
      const commentType = msts.commentTypeMap[state.comment.comment_type];
      return commentType?.icon_path || '/static/img/comment_icon_04.png';
    });
    const shouldShowShareScope = computed(() => {
      return state.user.id === state.comment.user_id;
    });
    const canCopyLink = computed(() => {
      return !!window.navigator.clipboard;
    });
    const copyLinkTooltip = computed(() => {
      return canCopyLink.value ? 'リンクをコピー' : 'http環境ではこの機能は使えません';
    });
    const hasValidAngle = computed(() => {
      return validateAngle(state.comment.angle);
    });
    const canEdit = computed(() => {
      return state.user.id === state.comment.user_id;
    });
    const canDelete = computed(() => {
      return state.user.id === state.comment.user_id;
    });
    const editIconPath = computed(() => {
      const commentType = msts.commentTypeMap[state.editItem.comment_type];
      return commentType?.icon_path || '/static/img/comment_icon_04.png';
    });
    const isNew = computed(() => {
      return !state.comment.id;
    });
    const canUpdateTs = computed(() => {
      return !isNew.value && !state.comment.parent_id;
    });
    const editItemHasValidAngle = computed(() => {
      return validateAngle(state.editItem.angle);
    });
    const isFixedCandidateImages = computed(() => {
      // 動画プレイヤーから呼ばれたときは最初から候補画像が渡されている
      return props.elem?.candidateImages;
    });
    const canSave = computed(() => {
      const hasNoError = state.errorMsgs.length === 0;
      return validateComment(state.editItem) && hasNoError;
    });
    const showCancelButton = computed(() => {
      return props.forceShowCancelButton || !props.isCreateSingleNewElement;
    });
    const kpDisp = computed(() => {
      if (!commentKpObject.value) { return ''; }
      return getLocationDispOfKp(commentKpObject.value, msts.roadNameDispMap);
    });

    // methods
    const copyLink = () => {
      const link = `${location.origin}/?comment_id=${props.elem.data.id}`;
      window.navigator.clipboard.writeText(link);
    };
    const showPrevious = () => {
      emit('show-previous', props.elem);
    };
    const showNext = () => {
      emit('show-next', props.elem);
    };
    const confirmDelete = () => {
      emit('confirm-delete', {
        dataName: 'comment',
        data: state.comment,
        confirmMessage: '選択中の付箋を削除してもよろしいですか？',
      });
    };
    const onSelectedKpObjChanged = () => {
      state.editItem = {
        ...state.editItem,
        angle: state.selectedKpObj && state.selectedKpObj.angle !== null ? state.selectedKpObj.angle : null,
      };
    };
    const handleFileUpload = () => {
      state.errorMsgs = [];
      state.candidateFiles = [];
      const fileList = refUploadFile.value?.files;
      if (!fileList) {
        return;
      }
      try {
        state.candidateFiles = convertFilesToFileObjs(fileList);
      } catch (error) {
        state.errorMsgs.push(...getErrorMessages(error as AxiosError));
      }
    };
    const save = async() => {
      // 更新の場合のみ通知フラグを送信する。
      if (!props.isCreateSingleNewElement) {
        state.editItem.update_ts = state.update_ts;
      }

      // 更新なしの場合はfileパラメータをサーバーに送信しない
      delete state.editItem.files;
      const selectedFile = state.candidateFiles.find(e => e.selected);
      if (selectedFile && refUploadFile.value?.files && refUploadFile.value.files?.length > 0) {
        state.editItem.files = Array.from(refUploadFile.value.files);
      } else if (selectedFile && props.elem.candidateImages) {
        // 「現在位置に付箋を追加」
        state.editItem.files = [props.elem.candidateImages[selectedFile.id]];
      } else if (!selectedFile && state.savedFile) {
        // 付箋画像削除時
        state.editItem.remove_file_flag = true;
      }
      state.editItem.kp_uid = state.selectedKpObj ? state.selectedKpObj.kp_uid : null;
      emit('update', { dataName: 'comment', data: state.editItem });
    };
    const showImage = () => {
      const modalTitle = state.comment.content || '';
      const commentTs = dtFormat(state.comment.ts, 'yyyymmddHHMMSS');
      const downloadFilenameTpl = `付箋添付画像_${state.comment.id}_${commentTs}_$TIMESTAMP.jpg`;
      emit('show-image', {
        imageSrc: state.savedFile,
        modalTitle,
        downloadFilenameTpl,
      });
    };
    const downloadPdf = () => {
      if (!state.savedFile) { return; }
      const commentTs = dtFormat(state.comment.ts, 'yyyymmddHHMMSS');
      const timestamp = dtFormat(new Date(), 'yyyymmddHHMMSS');
      const downloadFilenameTpl = `付箋添付PDF_${state.comment.id}_${commentTs}_${timestamp}.pdf`;
      downloadObjectUrl(state.savedFile, state.savedFileName || downloadFilenameTpl);
    };
    const cancelEdit = () => {
      releaseCandidateFiles();
      if (props.forceShowCancelButton) {
        emit('cancel-edit');
        return;
      }
      state.mode = 'show';
      emit('component-resized');
    };
    const selectImage = (image: CandidateFile) => {
      state.candidateFiles.forEach(candidate => {
        if (candidate !== image) {
          candidate.selected = false;
        }
      });
      image.selected = !image.selected;
    };
    const removeFileCandidate = (fileId: number) => {
      state.candidateFiles = state.candidateFiles.filter(e => e.id !== fileId);
    };

    return {
      ...toRefs(state),
      msts,
      // refs
      refUploadFile,
      // computeds
      nearKps,
      iconPath,
      shouldShowShareScope,
      copyLinkTooltip,
      canCopyLink,
      hasValidAngle,
      canEdit,
      canDelete,
      editIconPath,
      canUpdateTs,
      editItemHasValidAngle,
      isFixedCandidateImages,
      canSave,
      showCancelButton,
      kpDisp,
      // methods
      copyLink,
      showPrevious,
      showNext,
      startEdit,
      confirmDelete,
      onSelectedKpObjChanged,
      handleFileUpload,
      save,
      showImage,
      downloadPdf,
      cancelEdit,
      selectImage,
      removeFileCandidate,
      dtFormat,
      // others
      nearKpThresholdMeters,
    };
  },
});
