


import {
  computed,
  defineComponent,
  PropType,
  onMounted,
  ref,
} from '@vue/composition-api';
import { dtFormat } from '@/lib/dateHelper';
import { ModalPhoto } from '@/models/photoModal';

export default defineComponent({
  name: 'photo-modal',
  props: {
    title: {
      type: String,
      required: true,
    },
    photos: {
      type: Array as PropType<ModalPhoto[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedPhoto = ref<ModalPhoto | null>(null);
    const closePhotoModal = () => {
      emit('close-photo-modal');
    };
    const isSelectedPhoto = (photo: ModalPhoto): boolean => {
      return photo.id === selectedPhoto.value?.id;
    };
    const onClickPhoto = (photo: ModalPhoto): void => {
      selectedPhoto.value = photo;
    };
    const photoIndex = computed<number>(() => {
      if (!selectedPhoto.value) {
        return -1;
      }
      return props.photos.indexOf(selectedPhoto.value);
    });
    const isHead = computed<boolean>(() => {
      return photoIndex.value <= 0;
    });
    const isTail = computed<boolean>(() => {
      return photoIndex.value === props.photos.length - 1;
    });
    const showPrevious = () => {
      selectedPhoto.value = props.photos[photoIndex.value - 1];
    };
    const showNext = () => {
      selectedPhoto.value = props.photos[photoIndex.value + 1];
    };
    onMounted(async() => {
      selectedPhoto.value = props.photos[0];
    });
    return {
      selectedPhoto,
      isHead,
      isTail,
      closePhotoModal,
      isSelectedPhoto,
      onClickPhoto,
      showPrevious,
      showNext,
      dtFormat,
    };
  },
});
