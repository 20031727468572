


import {
  defineComponent,
  computed,
  onMounted,
  watch, onUnmounted,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import RealtimeModePane from '@/components/CleaningMap/components/RealtimeView/RealtimeModePane/index.vue';
import PhotoModal from '@/components/lib/PhotoModal/index.vue';
import MapMiscInfo from '@/components/CleaningMap/components/common/MapMiscInfo.vue';
import { CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';
import { CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { ABILITY_CLEANING_MAP } from '@/consts/ability';
import ManualDownloadLinks from '@/components/CleaningMap/components/common/ManualDownloadLinks/index.vue';
import usePaneControl from '@/components/CleaningMap/composables/usePaneControl';
import { useExtremeMap } from '@/components/CleaningMap/composables/useExtremeMap';
import { usePhotoModal } from '@/components/CleaningMap/composables/usePhotoModal';
import { CAR_LIST_MAX_HEIGHT_RATE, CAR_LIST_MIN_HEIGHT_RATE } from '@/components/CleaningMap/consts/cleaning_map';
import { fetchImageAsObjectURL } from '@/lib/imageHelper';
import { useWaitSpinner } from '@/components/CleaningMap/composables/useWaitSpinner';
import useCleaningCars from '@/components/CleaningMap/composables/useCleaningCars';
import { CleaningCarExt } from '@/models';

export default defineComponent({
  setup() {
    const store = useStore();
    const userState = store.state.user;
    const shouldShowManualDownloadLinks = computed<boolean>(() => {
      return !!userState.abilityMap[ABILITY_CLEANING_MAP];
    });

    const {
      paneState,
      onChangeShowCarIcons: onChangeShowCarIconsOrig,
      onChangeShowCleaningPhotoIcons: onChangeShowCleaningPhotoIconsOrig,
      onChangeShowDefectPhotoIcons: onChangeShowDefectPhotoIconsOrig,
    } = usePaneControl({
      paneStyleLimitMap: {
        listMinHeightRate: CAR_LIST_MIN_HEIGHT_RATE,
        listMaxHeightRate: CAR_LIST_MAX_HEIGHT_RATE,
      },
    });

    const {
      extremeMapRef,
      mapViewState,
      paneStyle,
      loadExtremeMapEssentials,
      forceResizeExtremeMap,
      redrawCarLayer,
      showCleaningCarPopup,
      showCleaningReportPhotoPopup,
      showCleaningMtxPopup,
      hidePopup,
    } = useExtremeMap(paneState);

    const {
      carState,
      startCarUpdateInterval,
      stopCarUpdateInterval,
      selectCar,
      deselectCar,
      deselectSelectedCarReportPhotos,
      redrawCarLayerWatchValue,
    } = useCleaningCars();

    watch(
      redrawCarLayerWatchValue,
      (value, oldValue) => {
        const fitToExtent = oldValue === 0;
        redrawCleaningCarLayer({ fitToExtent });
      },
    );

    const {
      photoModalState,
      showPhotoModal: showPhotoModalOrig,
      hidePhotoModal,
    } = usePhotoModal();
    const showPhotoModal = async(photoType: string, photos: CleaningReportPhotoExt[]) => {
      waitSpinnerState.showWaitSpinner = photos.some(photo => !photo.savedImage);
      const promises = photos.map<Promise<void>>(async(photo) => {
        if (photo.savedImage) return;
        photo.savedImage = await fetchImageAsObjectURL(photo.image_path);
      });
      await Promise.all(promises);
      waitSpinnerState.showWaitSpinner = false;
      showPhotoModalOrig(photoType, photos);
    };

    const waitSpinnerState = useWaitSpinner();

    const movingCarsCount = computed<number>(() => {
      return carState.cars.filter(car => car.isMoving).length;
    });
    const stoppedCarsCount = computed<number>(() => {
      return carState.cars.filter(car => !car.isMoving).length;
    });

    interface RedrawCleaningCarLayerOptions {
      fitToExtent?: boolean;
      setSelectedCarToCenter?: boolean;
    }
    const redrawCleaningCarLayer = (options: RedrawCleaningCarLayerOptions = {}) => {
      redrawCarLayer(options);
    };

    const onChangeShowCarIcons = () => {
      onChangeShowCarIconsOrig();
      redrawCleaningCarLayer();
    };
    const onChangeShowCleaningPhotoIcons = () => {
      onChangeShowCleaningPhotoIconsOrig();
      redrawCleaningCarLayer();
    };
    const onChangeShowDefectPhotoIcons = () => {
      onChangeShowDefectPhotoIconsOrig();
      redrawCleaningCarLayer();
    };

    const onClickCar = async(selectedCar: CleaningCarExt) => {
      hidePopup();
      // 選択中の車両をクリックした場合、選択を解除する
      if (deselectCar(selectedCar.id)) {
        redrawCleaningCarLayer();
        return;
      }
      await selectCar(selectedCar.id);
      showCleaningCarPopup(selectedCar);
      redrawCleaningCarLayer({ setSelectedCarToCenter: true });
    };

    const onClickPhotoPin = async(photo: CleaningReportPhotoExt) => {
      const isSelected = photo.isSelected;
      hidePopup();
      deselectSelectedCarReportPhotos();
      photo.isSelected = !isSelected;
      if (photo.isSelected) {
        // 画像を取得する
        if (!photo.savedImage) {
          waitSpinnerState.showWaitSpinner = true;
          photo.savedImage = await fetchImageAsObjectURL(photo.image_path);
          waitSpinnerState.showWaitSpinner = false;
        }
        showCleaningReportPhotoPopup(photo);
      }
      redrawCleaningCarLayer();
    };
    const onClickMtx = (mtx: CleaningMtxExt) => {
      const isSelected = mtx.isSelected;
      hidePopup();
      deselectSelectedCarReportPhotos();
      mtx.isSelected = !isSelected;
      if (mtx.isSelected) {
        showCleaningMtxPopup(mtx);
      }
      redrawCleaningCarLayer();
    };
    const onAllDeselected = () => {
      hidePopup();
      deselectSelectedCarReportPhotos();
      redrawCleaningCarLayer();
    };

    const paneResizeStopped = () => {
      forceResizeExtremeMap();
    };

    onMounted(async() => {
      waitSpinnerState.showWaitSpinner = true;
      await Promise.all([loadExtremeMapEssentials(), startCarUpdateInterval()]);
      waitSpinnerState.showWaitSpinner = false;
    });

    onUnmounted(() => {
      stopCarUpdateInterval();
    });

    return {
      mapViewState,
      paneState,
      paneStyle,
      carState,
      photoModalState,
      // ref
      extremeMapRef,
      // computed
      shouldShowManualDownloadLinks,
      // methods
      onClickCar,
      onClickPhotoPin,
      onClickMtx,
      onAllDeselected,
      onChangeShowCarIcons,
      onChangeShowCleaningPhotoIcons,
      onChangeShowDefectPhotoIcons,
      paneResizeStopped,
      showPhotoModal,
      hidePhotoModal,
      movingCarsCount,
      stoppedCarsCount,
    };
  },
  components: {
    RealtimeModePane,
    PhotoModal,
    ManualDownloadLinks,
    MapMiscInfo,
  },
});
