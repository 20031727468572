import { MapViewState, PaneState, PaneStyle, GeoItemLayerShow } from '@/models/johaisetsu/johaisetsuMap';
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  Ref,
  ref,
  UnwrapRef,
} from '@vue/composition-api';
import {
  LIST_HARD_MAX_HEIGHT,
  PANE_SIDE_HARD_MAX_WIDTH,
  PANE_SIDE_MAX_WIDTH_RATE,
  PANE_SIDE_MIN_WIDTH_RATE,
  VERTICAL_PANE_PADDING_NUMBER,
} from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsu/johaisetsuHelper';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { useStore } from '@/hooks/useStore';
import { Settings as UserSettings, Ability } from '@/models/apis/user/userResponse';
import { JohaisetsuCarExt } from '@/models/johaisetsu/johaisetsuCar';
import { JohaisetsuMtxExt } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';
import { JohaisetsuReportExt } from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import { useRoute } from '@/hooks/useRoute';
import { SettouPatrolReportExt } from '@/models/index';
import { getGeoItemSearchTimestamps } from '@/lib/utils';
import { getJohaisetsuSettouPatrolReports } from '@/lib/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolReportHelper';
import { GeoItemSearchConds } from '@/models/geoItem';
import {
  SETTOU_ONSITE_MANAGER_DISPLAY_NAME,
  SETTOU_PROGRESS_MANAGER_DISPLAY_NAME,
  SETTOU_PATROL_DISPLAY_NAME,
  SettouOnsiteManagementWorkType,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import {
  SettouOnsiteManagementReportExt,
} from '@/models/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementReport';
import {
  getSettouOnsiteManagementReports,
} from '@/lib/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementReportHelper';

interface RedrawCarLayerOptions {
  fitToExtent?: boolean;
  setSelectedCarToCenter?: boolean;
}
export interface UseExtremeMapResult {
  extremeMapRef: Ref;
  mapViewState: UnwrapRef<MapViewState>;
  paneStyle: PaneStyle;
  loadExtremeMapEssentials: () => Promise<void>;
  forceResizeExtremeMap: () => void;
  redrawCarLayer: (options: RedrawCarLayerOptions) => void;
  showJohaisetsuReportLayer: (selectedReport: JohaisetsuReportExt, fitToExtent: boolean) => void;
  removeJohaisetsuReportLayer: () => void;
  selectSettouReportsLayer: (
    itemShow: GeoItemLayerShow,
    geoItemSearchConds: GeoItemSearchConds,
    abilityMap: Record<number, Ability>,
    changedReports: string[],
  ) => Promise<void>;
  showJohaisetsuCarPopup: (car: JohaisetsuCarExt) => void;
  showJohaisetsuMtxPopup: (mtx: JohaisetsuMtxExt) => void;
  showSettouReportDetailPage: (report: JohaisetsuReportExt, workType: string) => void;
  hidePopup: () => void;
  deselectAllSettouReports: () => void;
}

export function useExtremeMap(paneState: PaneState): UnwrapRef<UseExtremeMapResult> {
  const store = useStore();
  const userState = store.state.user;
  const userSettings = computed<UserSettings>(() => {
    return userState.settings;
  });

  const extremeMapRef = ref<InstanceType<typeof ExtremeMap>>();

  const paneStyle = reactive<PaneStyle>({
    paneSideMinWidth: PANE_SIDE_HARD_MAX_WIDTH,
    paneSideMaxWidth: PANE_SIDE_HARD_MAX_WIDTH,
    listMinHeight: LIST_HARD_MAX_HEIGHT,
    listMaxHeight: LIST_HARD_MAX_HEIGHT,
  });

  const mapViewState = reactive<MapViewState>({
    extremeMapEssentials: null,
    onResizeFunc: () => {},
  });

  const loadExtremeMapEssentials = async(): Promise<void> => {
    await Promise.all([waitForUserAndMasters(), waitForJohaisetsuMasters()]);
    mapViewState.extremeMapEssentials = {
      userSettings: userSettings.value,
      kpMap: window.master.kpMap,
    };
  };

  const resizeMap = (mapHeight: number) => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.setMapHeight(mapHeight);
    extremeMapRef.value.triggerResize();
  };

  const initResizeFunc = () => {
    mapViewState.onResizeFunc = () => {
      const headerH = 57;
      const searchBarH = 96;
      const h = window.innerHeight - headerH - searchBarH;
      const w = window.innerWidth - VERTICAL_PANE_PADDING_NUMBER;
      paneStyle.paneSideMinWidth = Math.min(Math.floor(w * PANE_SIDE_MIN_WIDTH_RATE), PANE_SIDE_HARD_MAX_WIDTH);
      paneStyle.paneSideMaxWidth = Math.min(Math.floor(w * PANE_SIDE_MAX_WIDTH_RATE), PANE_SIDE_HARD_MAX_WIDTH);
      paneStyle.listMinHeight = parseInt((h * (paneState.paneStyleLimitMap?.listMinHeightRate || 1.0)).toString());
      paneStyle.listMaxHeight = parseInt((h * (paneState.paneStyleLimitMap?.listMaxHeightRate || 1.0)).toString());
      resizeMap(h);
    };
    mapViewState.onResizeFunc();
    window.addEventListener('resize', mapViewState.onResizeFunc);
  };

  const showJohaisetsuReportLayer = (selectedReport: JohaisetsuReportExt, fitToExtent: boolean): void => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.showJohaisetsuReportLayer(selectedReport, fitToExtent);
  };

  const forceResizeExtremeMap = (): void => {
    mapViewState.onResizeFunc();
  };

  const redrawCarLayer = ({ fitToExtent }: RedrawCarLayerOptions = {}) => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.redrawCarLayer({
      hideCarIcons: !paneState.showCarIcons,
      fitToExtent: fitToExtent || false,
    });
  };

  const removeJohaisetsuReportLayer = (): void => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.removeJohaisetsuReportLayer();
  };

  const showJohaisetsuCarPopup = (car: JohaisetsuCarExt): void => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.showJohaisetsuCarPopup(car);
  };

  const showJohaisetsuMtxPopup = (mtx: JohaisetsuMtxExt): void => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.showJohaisetsuMtxPopup(mtx);
  };

  const fetchSettouOnsiteManagementReports = async(
    workType: SettouOnsiteManagementWorkType,
    geoItemSearchConditions: GeoItemSearchConds,
    abilityMap: Record<number, Ability>,
    currentDataMap: Record<string, SettouOnsiteManagementReportExt>,
  ): Promise<SettouOnsiteManagementReportExt[]> => {
    const reqObj = getGeoItemSearchTimestamps(geoItemSearchConditions);
    const filteredData = await getSettouOnsiteManagementReports(
      reqObj,
      abilityMap,
      workType,
    );
    const newData: SettouOnsiteManagementReportExt[] = filteredData.map(e => {
      const current = currentDataMap[e.id];
      return {
        ...e,
        isSelected: current?.isSelected ?? false,
      };
    });

    return newData;
  };

  const selectSettouProgressManagerReports = async(
    itemShow: GeoItemLayerShow,
    geoItemSearchConds: GeoItemSearchConds,
    abilityMap: Record<number, Ability>,
  ) => {
    if (!extremeMapRef.value) return;

    if (itemShow.settouProgressManagerReports) {
      const currentDataMap: Record<string, SettouOnsiteManagementReportExt> = {
        ...extremeMapRef.value.getSettouProgressManagerReportMap(),
      };
      const settouOnsiteManagementReports = await fetchSettouOnsiteManagementReports(
        SETTOU_PROGRESS_MANAGER_DISPLAY_NAME,
        geoItemSearchConds,
        abilityMap,
        currentDataMap,
      );
      extremeMapRef.value.showSettouOnsiteManagementReportLayer(settouOnsiteManagementReports);
    } else {
      deselectSettouProgressManagerReportsLayer();
    }
  };

  const selectSettouOnsiteManagerReports = async(
    itemShow: GeoItemLayerShow,
    geoItemSearchConds: GeoItemSearchConds,
    abilityMap: Record<number, Ability>,
  ) => {
    if (!extremeMapRef.value) return;

    if (itemShow.settouOnsiteManagerReports) {
      const currentDataMap: Record<string, SettouOnsiteManagementReportExt> = {
        ...extremeMapRef.value.getSettouOnsiteManagerReportMap(),
      };
      const settouOnsiteManagementReports = await fetchSettouOnsiteManagementReports(
        SETTOU_ONSITE_MANAGER_DISPLAY_NAME,
        geoItemSearchConds,
        abilityMap,
        currentDataMap,
      );
      extremeMapRef.value.showSettouOnsiteManagerReportLayer(settouOnsiteManagementReports);
    } else {
      deselectSettouOnsiteManagerReportsLayer();
    }
  };

  const selectSettouReportsLayer = async(
    itemShow: GeoItemLayerShow,
    geoItemSearchConds: GeoItemSearchConds,
    abilityMap: Record<number, Ability>,
    changedReports: string[],
  ) => {
    if (changedReports.includes(SETTOU_PATROL_DISPLAY_NAME)) {
      if (itemShow.settouPatrolReports) {
        await selectSettouPatrolReportsLayer(geoItemSearchConds, abilityMap);
      } else {
        deselectSettouPatrolReportsLayer();
      }
    }
    if (!extremeMapRef.value) return;

    if (changedReports.includes(SETTOU_PROGRESS_MANAGER_DISPLAY_NAME)) {
      selectSettouProgressManagerReports(itemShow, geoItemSearchConds, abilityMap);
    }

    if (changedReports.includes(SETTOU_ONSITE_MANAGER_DISPLAY_NAME)) {
      selectSettouOnsiteManagerReports(itemShow, geoItemSearchConds, abilityMap);
    }
  };

  const selectSettouPatrolReportsLayer = async(
    geoItemSearchConds: GeoItemSearchConds,
    abilityMap: Record<number, Ability>,
  ) => {
    if (!extremeMapRef.value) return;

    const currentDataMap: Record<string, SettouPatrolReportExt> = {
      ...extremeMapRef.value.getSettouPatrolReportMap(),
    };
    const reqObj = getGeoItemSearchTimestamps(geoItemSearchConds);
    const data = await getJohaisetsuSettouPatrolReports(reqObj, abilityMap);
    const newData: SettouPatrolReportExt[] = data.map(e => {
      const current = currentDataMap[e.id];
      return {
        ...e,
        isSelected: current?.isSelected ?? false,
        isJohaisetsuDisplay: true,
      };
    });
    extremeMapRef.value.showSettouPatrolReportLayer(newData);
  };

  const deselectSettouPatrolReportsLayer = (): void => {
    if (!extremeMapRef.value) return;

    const currentDataMap: Record<string, SettouPatrolReportExt> = {
      ...extremeMapRef.value.getSettouPatrolReportMap(),
    };
    const isSelected = Object.values(currentDataMap).some(e => e.isSelected);
    if (isSelected) {
      extremeMapRef.value.hidePopup();
    }
    extremeMapRef.value.removeSettouPatrolReportLayer();
  };

  const deselectSettouProgressManagerReportsLayer = (): void => {
    if (!extremeMapRef.value) return;

    const currentDataMap: Record<string, SettouOnsiteManagementReportExt> = {
      ...extremeMapRef.value.getSettouProgressManagerReportMap(),
    };
    const isSelected = Object.values(currentDataMap).some(e => e.isSelected);
    if (isSelected) {
      extremeMapRef.value.hidePopup();
    }
    extremeMapRef.value.removeSettouOnsiteManagementReportLayer();
  };

  const deselectSettouOnsiteManagerReportsLayer = (): void => {
    if (!extremeMapRef.value) return;

    const currentDataMap: Record<string, SettouOnsiteManagementReportExt> = {
      ...extremeMapRef.value.getSettouOnsiteManagerReportMap(),
    };
    const isSelected = Object.values(currentDataMap).some(e => e.isSelected);
    if (isSelected) {
      extremeMapRef.value.hidePopup();
    }
    extremeMapRef.value.removeSettouOnsiteManagerReportLayer();
  };

  const deselectAllSettouReports = () => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.deselectAllSettouPatrolReports();
    extremeMapRef.value.deselectAllSettouOnsiteManagementReports();
    deselectSettouPatrolReportsLayer();
  };

  const hidePopup = (): void => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.hidePopup();
  };
  const { router } = useRoute();
  const showSettouReportDetailPage = (report: JohaisetsuReportExt, workType: string) => {
    const pathName = workType === SETTOU_PATROL_DISPLAY_NAME
      ? 'SettouPatrolDetail'
      : 'SettouOnsiteManagementDetail';
    const routeObj = {
      name: pathName,
      params: {
        id: report.id.toString(),
      },
    };
    const obj = router.resolve(routeObj);
    window.open(obj.href, '_blank');
  };

  onMounted(async() => {
    initResizeFunc();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', mapViewState.onResizeFunc);
  });

  return {
    extremeMapRef,
    mapViewState,
    paneStyle,
    loadExtremeMapEssentials,
    forceResizeExtremeMap,
    redrawCarLayer,
    removeJohaisetsuReportLayer,
    showJohaisetsuReportLayer,
    selectSettouReportsLayer,
    showJohaisetsuCarPopup,
    showJohaisetsuMtxPopup,
    showSettouReportDetailPage,
    hidePopup,
    deselectAllSettouReports,
  };
}
