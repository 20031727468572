import fileApi from '@/apis/file';
import { getResizedImage } from '@/lib/imageHelper';

export interface FileObj {
  id: number;
  content: string;
  type: string;
  name: string;
  file: File;
}
export const FILE_TYPE_UNKNOWN = 'unknown';

export async function fetchFileAsObjectInfo(filePath: string): Promise<{
  url: string | null;
  type: string | null;
} | null> {
  if (!filePath) {
    return null;
  }
  const { data: blob } = await fileApi.getFile({path: filePath});
  return {
    url: URL.createObjectURL(blob),
    type: blob.type,
  };
}

export async function convertFilesToFileObjs(fileList: FileList, maxId: number, limitMB: number): Promise<FileObj[]> {
  if (fileList.length === 0) { return []; }
  const fileObjs: FileObj[] = [];

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    if (file.type.includes('image/')) {
      await addImageFileObj(fileObjs, file, maxId + i + 1);
    } else {
      checkFileSize(file, limitMB);
      addGeneralFileObj(fileObjs, file, maxId + i + 1);
    }
  }
  return fileObjs;
}

async function addImageFileObj(fileObjs: FileObj[], file: File, id: number): Promise<void> {
  // リサイズ後は確実に10MBを切るのでサイズ確認はしなくてよい.
  const resizedImage = await getResizedImage(file, { maxWidth: 1000, maxHeight: 1000 });
  if (!resizedImage) return;
  fileObjs.push({
    id,
    content: URL.createObjectURL(resizedImage),
    type: file.type,
    name: file.name,
    file: new File([resizedImage], file.name, { type: file.type }),
  });
}

function addGeneralFileObj(fileObjs: FileObj[], file: File, id: number): void {
  fileObjs.push({
    id,
    content: URL.createObjectURL(file),
    type: file.type,
    name: file.name,
    file,
  });
}

function checkFileSize(file: File, limitMB: number): void {
  const fileSize = file.size;
  const limitSize = limitMB * 1024 * 1024;
  if (fileSize > limitSize) {
    throw new Error(`添付ファイルの最大サイズは${limitMB}MBです。`);
  }
}
