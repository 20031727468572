import { createInjection } from '@/lib/createInjection';
import { SettouMessageBoardState } from '@/models/settouMessageBoard';
import { ComputedRef, computed } from '@vue/composition-api';
import { BoardTab } from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import {
  BOARD_ROLE_CREATE,
  BOARD_ROLE_REPLY,
  departmentDispMap,
} from '@/components/SettouMessageBoard/consts/settou_message_board';
import { useStore } from '@/hooks/useStore';
import {
  JOHAISETSU_ROLE_EAST,
  JOHAISETSU_ROLE_KANAGAWA,
  JOHAISETSU_ROLE_WEST,
} from '@/consts/johaisetsu';
import { getBoardTabDefault } from '@/components/SettouMessageBoard/utils';

interface InjectedState {
  settouMessageBoardState: SettouMessageBoardState;
}
interface UseBoardDisplayControl {
  currentBoardTab: ComputedRef<BoardTab>;
  isHonbuBoard: ComputedRef<boolean>;
  isMaintenanceBoard: ComputedRef<boolean>;
  canUseHanSearchConditions: ComputedRef<boolean>;
  canUseHonbuReportSearchConditions: ComputedRef<boolean>;
  canUseCompanySearchConditions: ComputedRef<boolean>;
  boardTabDepartmentDisp: ComputedRef<string>;
  currentBoardRole: ComputedRef<number>;
  isCreatableRole: ComputedRef<boolean>;
  isRepliableRole: ComputedRef<boolean>;
}

const { provide, inject } = createInjection<InjectedState>('settouMessageBoard/useBoardDisplayControl');

export function provideBoardDisplayControl(settouMessageBoardState: SettouMessageBoardState): InjectedState {
  const injectedState = { settouMessageBoardState };
  provide(injectedState);
  return injectedState;
}

export default function useBoardDisplayControl(defaultInjectedState?: InjectedState): UseBoardDisplayControl {
  const injectedState = defaultInjectedState ?? inject();
  const settouMessageBoardState = injectedState.settouMessageBoardState;
  const store = useStore();
  const userState = store.state.user;

  const currentBoardTab = computed<BoardTab>(() => {
    return settouMessageBoardState.boardTabs.find(e =>
      e.id === settouMessageBoardState.currentTabId,
    ) ?? getBoardTabDefault();
  });
  const isHonbuBoard = computed<boolean>(() => {
    return currentBoardTab.value.board_kind === 'honbu';
  });
  const isMaintenanceBoard = computed<boolean>(() => {
    return currentBoardTab.value.board_kind === 'maintenance';
  });
  const isKyokuUser = computed<boolean>(() => {
    return userState.johaisetsu_role === JOHAISETSU_ROLE_WEST ||
      userState.johaisetsu_role === JOHAISETSU_ROLE_EAST ||
      userState.johaisetsu_role === JOHAISETSU_ROLE_KANAGAWA;
  });
  const canUseHanSearchConditions = computed<boolean>(() => {
    return isHonbuBoard.value;
  });
  const canUseHonbuReportSearchConditions = computed<boolean>(() => {
    return isHonbuBoard.value && isKyokuUser.value;
  });
  const canUseCompanySearchConditions = computed<boolean>(() => {
    return isMaintenanceBoard.value;
  });
  const boardTabDepartmentDisp = computed<string>(() => {
    return departmentDispMap[currentBoardTab.value.department];
  });
  const currentBoardRole = computed<number>(() => {
    return currentBoardTab.value.board_role;
  });
  const isCreatableRole = computed<boolean>(() => {
    return !userState.has_role_super_admin && currentBoardRole.value === BOARD_ROLE_CREATE;
  });
  const isRepliableRole = computed<boolean>(() => {
    return !userState.has_role_super_admin && currentBoardRole.value === BOARD_ROLE_REPLY;
  });

  return {
    currentBoardTab,
    isHonbuBoard,
    isMaintenanceBoard,
    canUseHanSearchConditions,
    canUseHonbuReportSearchConditions,
    canUseCompanySearchConditions,
    boardTabDepartmentDisp,
    currentBoardRole,
    isCreatableRole,
    isRepliableRole,
  };
}
