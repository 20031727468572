import settouOnsiteManagementReportApi from '@/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsu_settou_onsite_management_report';
import { ABILITY_SETTOU_REPORT_LIST } from '@/consts/ability';
import { dtFormat } from '@/lib/dateHelper';
import { Ability } from '@/models/apis/user/userResponse';
import {
  SettouOnsiteManagementReportsParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementReportsRequest';
import {
  SettouOnsiteManagementReport,
  SettouOnsiteManagementReports,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementReportsResponse';
import {
  SettouOnsiteManagementReportExt,
} from '@/models/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementReport';

export interface JohaisetsuSettouOnsiteManagementReportsParams {
  startTs: Date;
  endTs: Date;
}

export async function getSettouOnsiteManagementReports(
  params: JohaisetsuSettouOnsiteManagementReportsParams,
  abilityMap: Record<string, Ability>,
  workType: string,
): Promise<SettouOnsiteManagementReportExt[]> {
  const reqObj: SettouOnsiteManagementReportsParams = {
    ts_from: params.startTs,
    ts_to: params.endTs,
  };
  return getSettouOnsiteManagementReportsByParams(reqObj, abilityMap, workType);
}

export async function getSettouOnsiteManagementReportsByParams(
  params: SettouOnsiteManagementReportsParams,
  abilityMap: Record<string, Ability>,
  workType: string,
): Promise<SettouOnsiteManagementReportExt[]> {
  try {
    const { data } = await settouOnsiteManagementReportApi.index(params);
    const filteredData = workType ? filterSettouOnsiteManagementReportsByWorkType(data, workType) : data;
    return convSettouOnsiteManagementReports(filteredData, abilityMap);
  } catch (e) {
    return [];
  }
}

export function filterSettouOnsiteManagementReportsByWorkType(
  data: SettouOnsiteManagementReports,
  workType: string,
): SettouOnsiteManagementReport[] {
  return data.filter(e => e.work_type === workType);
}

export function convSettouOnsiteManagementReports(
  data: SettouOnsiteManagementReports,
  abilityMap: Record<string, Ability>,
): SettouOnsiteManagementReportExt[] {
  const result = data.map(e => ({
    ...e,
    ts: dtFormat(new Date(e.ts), 'yyyy/mm/dd HH:MM'),
    lat: parseFloat(e.lat),
    lon: parseFloat(e.lon),
    showDetailPageButton: !!abilityMap[ABILITY_SETTOU_REPORT_LIST],
    isSelected: false,
  }));

  // 同一地点の場合日付が新しいものが上
  result.sort((a, b) => (a.ts < b.ts ? -1 : a.ts > b.ts ? 1 : 0));

  return result;
}
