
import johaisetsuSettouPatrolReportApi from '@/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsu_settou_patrol_report';
import { ABILITY_SETTOU_REPORT_LIST } from '@/consts/ability';
import { dtFormat } from '@/lib/dateHelper';
import { Ability } from '@/models/apis/user/userResponse';
import { SettouPatrolReportsParams } from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolReportsRequest';
import { SettouPatrolReport } from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolReportsResponse';
import { SettouPatrolReportExt } from '@/models/index';

export interface JohaisetsuSettouPatrolReportsParams {
  startTs: Date;
  endTs: Date;
  roadNames?: string;
  direction?: string;
}

export async function getJohaisetsuSettouPatrolReports(params: JohaisetsuSettouPatrolReportsParams, abilityMap: Record<string, Ability>): Promise<SettouPatrolReportExt[]> {
  const reqObj: SettouPatrolReportsParams = {
    ts_from: params.startTs,
    ts_to: params.endTs,
  };
  if (params.roadNames) {
    reqObj.road_name_disps = params.roadNames;
  }
  if (params.direction) {
    reqObj.direction = params.direction;
  }
  return getJohaisetsuSettouPatrolReportsByParams(reqObj, abilityMap);
}
export async function getJohaisetsuSettouPatrolReportsByParams(params: SettouPatrolReportsParams, abilityMap: Record<string, Ability>): Promise<SettouPatrolReportExt[]> {
  try {
    const { data } = await johaisetsuSettouPatrolReportApi.index(params);
    return convJohaisetsuSettouPatrolReports(data, abilityMap);
  } catch (e) {
    return [];
  }
}
export function convJohaisetsuSettouPatrolReports(data: SettouPatrolReport[], abilityMap: Record<string, Ability>): SettouPatrolReportExt[] {
  const result = data.map(e => {
    return {
      ...e,
      ts: dtFormat(new Date(e.ts), 'yyyy/mm/dd HH:MM'),
      lat: parseFloat(e.lat),
      lon: parseFloat(e.lon),
      temperature: (e.temperature ? parseFloat(e.temperature) : undefined),
      road_temperature: (e.road_temperature ? parseFloat(e.road_temperature) : undefined),
      showDetailPageButton: !!abilityMap[ABILITY_SETTOU_REPORT_LIST],
      isSelected: false,
    };
  });
  // 同一地点の場合日付が新しいものが上
  result.sort((a, b) => {
    return a.ts < b.ts ? -1 : a.ts > b.ts ? 1 : 0;
  });
  return result;
}
