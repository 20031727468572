import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import {
  JohaisetsuSettouPatrolCreateParams,
  JohaisetsuSettouPatrolUpdateParams,
  JohaisetsuSettouPatrolStartUpdateParams,
  JohaisetsuSettouPatrolEndUpdateParams,
  JohaisetsuSettouPatrolBaseArrivalUpdateParams,
  JohaisetsuSettouPatrolGetCurrentParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolRequest';
import {
  JohaisetsuSettouPatrolResponse,
  SettouPatrolReportShowResponse,
} from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolResponse';
import { SettouReportsGetByCarIdsParams } from '@/models/apis/johaisetsu/settouPatrol/johaisetsuSettouPatrolRequestCommon';

export default {
  create(
    params: JohaisetsuSettouPatrolCreateParams): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_patrol'), params);
  },
  update(
    params: JohaisetsuSettouPatrolUpdateParams): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_patrol/update'), params);
  },
  updateStartTime(
    params: JohaisetsuSettouPatrolStartUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_patrol/update_start_time'), params);
  },
  updateEndTime(
    params: JohaisetsuSettouPatrolEndUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_patrol/update_end_time'), params);
  },
  updateBaseArrivalTime(
    params: JohaisetsuSettouPatrolBaseArrivalUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_patrol/update_base_arrival_time'), params);
  },
  getCurrent(
    params: JohaisetsuSettouPatrolGetCurrentParams,
  ): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_settou_patrol/current'), { params });
  },
  getByCarIds(
    params: SettouReportsGetByCarIdsParams,
  ): Promise<AxiosResponse<SettouPatrolReportShowResponse[]>> {
    return axios.get(utils.getApiUrl('johaisetsu_settou_patrol/get_by_car_ids'), { params });
  },
};
