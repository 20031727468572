


import {
  reactive,
  computed,
  defineComponent,
  PropType,
  watch,
} from '@vue/composition-api';
import { HanMaster } from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import { ONE_DAY_MSEC, SearchParams } from '@/components/SettouMessageBoard/utils/index';
import useBoardDisplayControl from '@/components/SettouMessageBoard/composables/useBoardDisplayControl';

const maxDateRange = 365;
interface SearchState {
  searchConditions: SearchParams;
  validatedSearchConditions: SearchParams;
}

export default defineComponent({
  name: 'search-pane',
  props: {
    currentTabId: {
      type: Number,
      required: true,
    },
    hanChoices: {
      type: Array as PropType<HanMaster[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const getDateFrom = (date: Date): Date => {
      return new Date(date.getFullYear(), date.getMonth() - 6, date.getDate());
    };
    const getInitialSearchConditions = (): SearchParams => {
      return {
        dateFrom: getDateFrom(today),
        dateTo: today,
        han: null,
        honshaReportNeeded: null,
        reportToHonsha: null,
        companyName: null,
      };
    };
    const searchState = reactive<SearchState>({
      searchConditions: getInitialSearchConditions(),
      validatedSearchConditions: getInitialSearchConditions(),
    });

    const {
      canUseHanSearchConditions,
      canUseHonbuReportSearchConditions,
      canUseCompanySearchConditions,
    } = useBoardDisplayControl();

    const isValidSearchParams = computed<boolean>(() => {
      return isValidSearchDateRange();
    });
    const isValidSearchDateRange = (): boolean => {
      const dateFrom = searchState.searchConditions.dateFrom.getTime();
      const dateTo = searchState.searchConditions.dateTo.getTime();
      return dateFrom <= dateTo && (dateTo - dateFrom) < (maxDateRange + 1) * ONE_DAY_MSEC;
    };
    const doSearch = () => {
      if (!isValidSearchParams.value) return;
      searchState.validatedSearchConditions = { ...searchState.searchConditions };
      const params: SearchParams = {
        ...searchState.searchConditions,
        han: canUseHanSearchConditions.value ? searchState.validatedSearchConditions.han : null,
        honshaReportNeeded:
        canUseHonbuReportSearchConditions.value ? searchState.validatedSearchConditions.honshaReportNeeded : null,
        reportToHonsha: canUseHonbuReportSearchConditions.value
          ? searchState.validatedSearchConditions.reportToHonsha
          : null,
        companyName: canUseCompanySearchConditions.value ? searchState.validatedSearchConditions.companyName : null,
      };
      emit('search', params);
    };
    watch(() => props.currentTabId, () => {
      // 前のタブで最後に確定した検索条件を新しいタブで利用する (入力中だが未確定だったものは無視する)
      searchState.searchConditions = { ...searchState.validatedSearchConditions };
      doSearch();
    });
    return {
      props,
      searchState,
      // computed
      canUseHanSearchConditions,
      canUseHonbuReportSearchConditions,
      canUseCompanySearchConditions,
      isValidSearchParams,
      // methods
      doSearch,
    };
  },
});
